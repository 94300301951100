import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Table.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { filterChannels, searchChannels, selectChannels, selectFilter, setFilterColorValues, setFilterIndex, setFilterValues, setHeadersLength, setIsChannelDeselected, setSelectedChannelIds, showAllChannels, sortChannels, toggleFilter, toggleSelectedButtonStatus, unSelectGroup } from 'src/redux/actions/actions.channelGroupsWidget';
import FilterBoxContainer from '../../containers/FilterBoxContainer/FilterBoxContainer';
import TableRow from '../../components/TableRow/TableRow';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { STATUS_SUCCEEDED } from 'src/redux/types/status';

const Table = ({ data, headers, onSelectionChange, onHistoryRowClick, historyChannels, isChannelGroupsWidget }) => {
    const [allSelected, setAllSelected] = useState(false);
    const [inputDataArray, setInputDataArray] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [checkedValues, setCheckedValues] = useState(() =>
    Array.from({ length: headers?.length }, () => ({ all: false }))
  );

    const listRef = useRef();
    const {i18n, t} = useTranslation()
    const isMobileOnTablet = useIsScreenSize(1024);
    const dispatch = useDispatch()
    const allChannels = useSelector((state) => state?.channelReducers)
    const { 
        allChannelsShown,
        isFilterBoxShown, 
        filterValuesArray, 
        searchedChannels, 
        selectedFilter, 
        selectedGroupId, 
        filteredChannels,
        selectedSortType,
        selectedChannels,
        saveStatusLog,
        filterBoxIndex,
        filterColorValues,
        selectedButtonStatus,
        channelsDeselected,
        selectedChannelIds,
        selectedGroupTitle } = useSelector((state) => state.channelGroupsWidgetReducer);
        const conditionalChannelIds = historyChannels?.query && !selectedChannelIds?.length ? historyChannels?.selectedChannels : selectedChannelIds

        const [checkedItems, setCheckedItems] = useState({});
        
        const memorizedFilterChannels = useMemo(() => filteredChannels, [filteredChannels])
        const memorizedSearchChannels = useMemo(() => searchedChannels, [searchedChannels])
        const memorizedAllChannels = useMemo(() => allChannels, [allChannels])

        //Indicator colors initiliaizer for both views
        useEffect(() => {
          const initialColorValues = Array(headers?.length)?.fill('#000000');
          dispatch(setFilterColorValues(initialColorValues))
        },[memorizedAllChannels, selectedGroupTitle])

        const filterChannelObjectFromList = () => {
          const keys = Object?.keys(checkedItems);
          const items = keys?.map(key => ({
            key: key,
            value: checkedItems[key]
          }));
          const filteredValue = [...(allChannels?.data || []), ...(filteredChannels || [])].filter((channel) => {
            // Decide which list to filter from based on the channel properties.
            if (channel.isNotAvailable || channel.isUnique) {
              return filteredChannels?.includes(channel) && items?.map((item) => item?.key)?.includes(channel?.Name);
            } else {
              return allChannels?.data?.includes(channel) && items?.map((item) => item?.key)?.includes(channel?.name);
            }
          })
          return filteredValue;
        };

        const initializeCheckedItems = () => {
          if (isChannelGroupsWidget) {
              return {};
          }
          const initialCheckedItems = {};
          if(conditionalChannelIds){
              conditionalChannelIds?.forEach(id => {
                  initialCheckedItems[data.find(item => item.Id === id)?.Name] = true;
              });
          }
          return initialCheckedItems;
      };

      useEffect(() => {
        dispatch(setSelectedChannelIds(historyChannels?.selectedChannels))
      },[onHistoryRowClick, allChannelsShown, historyChannels])

      useEffect(() => {
      
      },[selectedChannels])

      useEffect(() => {
        setCheckedItems(initializeCheckedItems());
        dispatch(setFilterValues([]))
        !checkedItems?.length && isChannelGroupsWidget && dispatch(setSelectedChannelIds([]))
    }, [isChannelGroupsWidget]);

        useEffect(() => {
          dispatch(setHeadersLength(headers?.length))
        }, [])
        
        //When change happened between widgets clear group logic
         useEffect(() => {
          if (!isChannelGroupsWidget && filteredChannels?.length) {
            dispatch(unSelectGroup());
            dispatch(searchChannels(allChannels?.data))
            dispatch(showAllChannels(true));
          }
        }, [isChannelGroupsWidget, dispatch]);

        useEffect(() => {
          if(onSelectionChange && !isChannelGroupsWidget){
            onSelectionChange(conditionalChannelIds || [])
          }
        },[checkedItems])

        //Search widget CGW DeselectAll logic
        useEffect(() => {
          if(!isChannelGroupsWidget && channelsDeselected){
            dispatch(setSelectedChannelIds([]))
            onSelectionChange([])
          }
        }, [channelsDeselected])

        //Different view conditions such as group view, allchannels view, sorted channels view etc.
        useEffect(() => {
            if (allChannels && !filterValuesArray?.length && !selectedButtonStatus && !selectedGroupId) {
                dispatch(searchChannels(memorizedAllChannels?.data));
            }
            if (!filterValuesArray?.length && allChannelsShown && !selectedButtonStatus && !selectedGroupId) {
                dispatch(searchChannels(memorizedAllChannels?.data));
            }
        }, [allChannels, isFilterBoxShown, allChannelsShown, searchedChannels, filterValuesArray])

        useEffect(() => {
            selectedGroupId && !filterValuesArray?.length && dispatch(searchChannels(memorizedFilterChannels))
            selectedGroupId && !searchedChannels?.length && dispatch(searchChannels(memorizedFilterChannels))
        }, [filteredChannels, selectedGroupId, filterValuesArray])

        useEffect(() => {
            !filterValuesArray?.length && allChannelsShown && !selectedButtonStatus && dispatch(searchChannels(memorizedAllChannels?.data))
            !filterValuesArray?.length && selectedGroupId && !selectedButtonStatus && dispatch(searchChannels(memorizedFilterChannels))
            selectedButtonStatus && isFilterBoxShown && dispatch(searchChannels(memorizedSearchChannels))
            selectedButtonStatus && isFilterBoxShown && filterValuesArray?.length && dispatch(searchChannels(filterValuesArray))
            selectedButtonStatus && filterValuesArray?.length && !allChannelsShown && dispatch(searchChannels(filterValuesArray))
        }, [searchedChannels, filterValuesArray, selectedGroupId, isFilterBoxShown])

        useEffect(() => {
            filterValuesArray?.length && isFilterBoxShown && dispatch(searchChannels(memorizedSearchChannels))
            isFilterBoxShown && selectedGroupId && !filterValuesArray?.length && !selectedButtonStatus && dispatch(searchChannels(memorizedFilterChannels))
            !searchedChannels?.length && allChannelsShown &&  dispatch(searchChannels(memorizedAllChannels?.data))
            !searchedChannels?.length && selectedGroupId && dispatch(searchChannels(memorizedFilterChannels))
        },[searchedChannels, isFilterBoxShown])
        
        useEffect(() => {
            !filterValuesArray?.length && allChannelsShown && isFilterBoxShown && !selectedButtonStatus && dispatch(searchChannels(memorizedAllChannels?.data));
            !filterValuesArray?.length && selectedGroupId && isFilterBoxShown && dispatch(filterChannels(memorizedFilterChannels));
            !filterValuesArray?.length && allChannelsShown && dispatch(sortChannels(memorizedAllChannels?.data))
        },[allChannels, allChannelsShown, filterValuesArray])
        
        useEffect(() => {
            isFilterBoxShown && filterValuesArray?.length && allChannelsShown && dispatch(sortChannels(memorizedSearchChannels))
            !filterValuesArray?.length && allChannelsShown && selectedSortType && dispatch(sortChannels(memorizedAllChannels?.data))
        },[selectedSortType, searchedChannels, allChannels, isFilterBoxShown])

        useEffect(() => {
          const filteredChannels = filterChannelObjectFromList();
          filteredChannels && dispatch(selectChannels(filteredChannels));
        }, [checkedItems]);

        useEffect(() => {
          selectedButtonStatus && dispatch(searchChannels(selectedChannels))
          selectedButtonStatus && filterValuesArray?.length && dispatch(searchChannels(selectedChannels))
          selectedButtonStatus && filterValuesArray?.length && !allChannelsShown && dispatch(searchChannels(selectedChannels))
          selectedButtonStatus && filterValuesArray?.length && allChannelsShown && dispatch(searchChannels(selectedChannels))
          selectedGroupId && selectedButtonStatus && filterValuesArray?.length && dispatch(searchChannels([]))
          !selectedButtonStatus && !selectedChannels?.length && dispatch(searchChannels(filterValuesArray))
        },[selectedButtonStatus, searchChannels, allChannelsShown, selectedChannels, selectedGroupId])

        useEffect(() => {
          if(!selectedChannels?.length){
            dispatch(searchChannels(allChannels?.data))
            if(inputDataArray?.length){
              dispatch(searchChannels(searchedChannels))
            }
            dispatch(toggleSelectedButtonStatus(false))
            setAllSelected(false);
            if(selectedGroupId && isChannelGroupsWidget){
              dispatch(searchChannels(filteredChannels))
            }
            filterValuesArray?.length && Object.keys(checkedItems).length === 0 && dispatch(searchChannels(filterValuesArray))
          }
          
        },[selectedChannels])

        useEffect(() => {
          channelsDeselected && setCheckedItems([])
          dispatch(setIsChannelDeselected(false))
        }, [channelsDeselected])

        //Show Behaviour on Different Views
        useEffect(() => {
            !isFilterBoxShown && selectedFilter && dispatch(toggleFilter(true));
            !filterValuesArray?.length && dispatch(setFilterValues([]))
            selectedFilter && selectedGroupId && dispatch(searchChannels(searchedChannels))
        },[selectedFilter, dispatch])

        //Hide Behaviour on Different Views
        useEffect(() => {
            dispatch(toggleFilter(false));
            setAllSelected(false)
        }, [allChannelsShown, selectedGroupTitle, filteredChannels])


        const updateSelectAllCheckbox = () => {
          const numChecked = Object.values(checkedItems).filter(Boolean).length;
          if (data.length - 1) {
            setAllSelected(false);
          } else {
            setAllSelected(numChecked === data.length);
          }
        };

        //Persist checked Items
        useEffect(() => {
          const updatedCheckedItems = {...checkedItems};
          if (conditionalChannelIds && !isChannelGroupsWidget) {
              conditionalChannelIds?.forEach(id => {
                  const itemName = data.find(item => item.Id === id)?.Name;
                  if (itemName) {
                      updatedCheckedItems[itemName] = true;
                  } else {
                      Object.keys(updatedCheckedItems)?.forEach(key => {
                          if (updatedCheckedItems[key] === id) {
                              delete updatedCheckedItems[key];
                          }
                      });
                  }
              });
          }
      
          !isChannelGroupsWidget && setCheckedItems(updatedCheckedItems);
      }, [conditionalChannelIds, allChannels?.data]);        

        //Channels select all handler
        const handleSelectAllChange = (checked) => {
          setAllSelected(checked);
          const newCheckedItems = {...checkedItems};
          const newSelectedIds = [];
        
          data?.forEach((item) => {
              newCheckedItems[item[t('Name')]] = checked;
              newSelectedIds.push(item.Id);
          });
        
          setCheckedItems(newCheckedItems);
            if (selectedGroupId) {
              if (!checked) {
                  // Remove newSelectedIds items from newCheckedItems
                  newSelectedIds.forEach((id) => {
                      const item = data.find((item) => item.Id === id);
                      if (item) {
                          delete newCheckedItems[item[t('Name')]];
                      }
                  });
              }
          } else {
              // When selectedGroupId does not exist
              !isChannelGroupsWidget &&
                  dispatch(setSelectedChannelIds(checked ? newSelectedIds : []));
              if (!checked) {
                  setCheckedItems({});
                  !isChannelGroupsWidget && onSelectionChange([]);
              }
          }
        };
        
        //If any channels added or removed make all unchecked 
        useEffect(() => {
          !selectedChannels?.length && saveStatusLog?.isSuccess && setCheckedItems({})
          !selectedChannels?.length && saveStatusLog?.isSuccess && setAllSelected(false)
        },[saveStatusLog])

        //Channels checkbox handler
        const handleCheckboxChange = (checked, name, id) => {
            if (checked) {
                setCheckedItems({ ...checkedItems, [name]: checked });
                !isChannelGroupsWidget && dispatch(setSelectedChannelIds([ ...(conditionalChannelIds || []), id ]));
              } else {
                const newCheckedItems = { ...checkedItems };
                delete newCheckedItems[name];
                setCheckedItems(newCheckedItems);
                !isChannelGroupsWidget && dispatch(setSelectedChannelIds(conditionalChannelIds?.filter(itemId => itemId !== id)));
                if (!isChannelGroupsWidget && Object.keys(newCheckedItems).length === 0) {
                  onSelectionChange([])
              }
                !selectedChannels?.length && setCheckedItems({})
            }
            setAllSelected(
                Object.values({ [name]: checked }).every((value) => value)
            );
            updateSelectAllCheckbox();
        };

          const filterBoxToggleHandler = (index, name) => {
            dispatch(selectFilter(name));

            if (index === filterBoxIndex) {
              dispatch(toggleFilter(!isFilterBoxShown));
            } else {
              dispatch(setFilterIndex(index));
              dispatch(toggleFilter(false));
            }
          };
   
          const filterBoxToggleConditional = (index) => {
            return isFilterBoxShown && index === filterBoxIndex && (
              <div className={styles.filterOverlay}>
                {isFilterBoxShown && <FilterBoxContainer 
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
                inputDataArray={inputDataArray}
                setInputDataArray={setInputDataArray}
                setAllSelected={setAllSelected}
                />}
              </div>
            );
          };

          //ReactWindow Row creation

          const handleShowToggle = (index, e) => {
            isMobileOnTablet && e.stopPropagation()
            setExpandedRows(prevState => ({
              ...prevState,
              [index]: !prevState[index]
            }));
        
            listRef.current.resetAfterIndex(index, true);
          };

          const isFilterResultEmpty = data?.[0]?.Name === null
          const Row = ({ index, style, data }) => {
            const item = data?.[index];
            return (
              <div style={style} className={styles.row}>
                <TableRow 
                    key={item?.Id} 
                    item={item} 
                    index={index} 
                    checkedItems={checkedItems} 
                    tableHeaders={headers} 
                    handleCheckboxChange={handleCheckboxChange}
                    itemData={{ data, headers, checkedItems }}
                    isMobile={isMobileOnTablet}
                    handleShowToggle={handleShowToggle}
                    expandedRows={expandedRows}
                    />
              </div>
            );
          };

          const conditionalTable = () => {
            if (allChannels?.status === STATUS_SUCCEEDED && !allChannels?.data?.length) {
              // show msg if channels call sent but got no channels (e.g. request failed or user has no channels)
              return <span className={styles.noFilterResultText} data-testid="cgNoResultsText">
                {t("No channels loaded")}
                </span>
            }
            //Show loading when loading state || show "No match" for empty filter results
            else if (!searchedChannels
               || 
               (selectedGroupId && !filteredChannels)
               || 
               !allChannels?.data
               ||
               !allChannelsShown && !selectedGroupId
               || !data?.length && !selectedGroupId
               ){
              return <LoadingSpinner size={"5rem"}/>
            }else if(isFilterResultEmpty){
              return <span className={styles.noFilterResultText} data-testid="cgNoResultsText">
                {t("No match")}
                </span>
            }else if(!filteredChannels?.length && selectedGroupId && !selectedButtonStatus){
              return <span className={styles.noFilterResultText} data-testid="cgNoResultsText">
                {t("No channels in group")}
                </span>
            }else{
              return <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={data?.length}
                          itemSize={(index) => expandedRows[index] && isMobileOnTablet ? 55 : 50}
                          width={width}
                          itemData={data}
                          ref={listRef}
                        >
                          {Row}
                        </List>
                      )}
                    </AutoSizer>
            }
          }

          return (
              <div
                  className={`${styles.table} ${i18n.dir() === 'rtl' ? styles.rtl : ''}`}
                  data-testid="cg-tableContainer"
              >
                  <div className={styles.headerCell}>
                      {headers?.map((item, index) =>
                          index === 0 ? (
                              <div key={index} className={`${styles.firstColumn}`}>
                                  <Checkbox
                                      className={styles.checkbox}
                                      withBorder={true}
                                      onClick={handleSelectAllChange}
                                      checked={allSelected}
                                      disabled={!searchedChannels?.length}
                                  />
                                  <span>{t(item)}</span>
                                  <FontAwesomeIcon
                                      data-testid={`cgFilterIcon-${item}`}
                                      onClick={() => filterBoxToggleHandler(index, item)}
                                      id={index}
                                      className={styles.triangle}
                                      icon={faFilter}
                                      style={{ color: filterColorValues?.[index] }}
                                  />

                                  {filterBoxToggleConditional(index)}
                              </div>
                          ) : (
                              <div className={styles.cell} key={index}>
                                  <span>{t(item)}</span>
                                  <FontAwesomeIcon
                                      data-testid={`cgFilterIcon-${item}`}
                                      onClick={() => filterBoxToggleHandler(index, item)}
                                      id={index}
                                      className={styles.triangle}
                                      icon={faFilter}
                                      style={{ color: filterColorValues?.[index] }}
                                  />

                                  {filterBoxToggleConditional(index)}
                              </div>
                          )
                      )}
                  </div>
                  <div
                      className={styles.tbody}
                      style={{
                          minWidth: '45rem',
                          width: '100%',
                          minHeight: '25rem',
                          height: '100%'
                      }}
                  >
                      {conditionalTable()}
                  </div>
              </div>
          );
};

export default Table;