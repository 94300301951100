import React from 'react';
import styles from './SearchSettings.module.css';
import DateRangePicker from 'src/Widgets/common/basicElements/DateRangePicker/DateRangePicker';
import Select from 'react-select';
import classNames from 'classnames';
import formStyles from '../../common/basicStyles/forms.module.scss';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';

class SearchOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.t = this.props.t;
    }

    renderByChannel() {
        const selectedChannels = this.props.selectedChannels;
        const channelMap = {};
        this.props.channels.forEach(channel => {
            channelMap[channel.id] = channel;
        });

        const selectedChannelsString = this.props.selectedChannels
        .map(c => channelMap[c] ? channelMap[c].text : 'Unknown Channel')
        .join(', ');

        return (
            <div className={classNames(styles.center, styles.radioButtonContent)}>
                <Button
                    type='primary'
                    onClick={this.props.onChannelSelection}
                    title={selectedChannelsString}
                    disabled={!this.props.channels.length}
                >
                    {this.t('Select Channels')} ({selectedChannels.length})
                </Button>
            </div>
        );       
    }

    renderByChannelGroup(settings, channelGroups, isDisabled) {
        return (
            <div data-testid="search-channelgroup-select" className={classNames(styles.radioButtonContent)}>
                <Select
                    isDisabled={isDisabled}
                    placeholder={this.t("Select Channel Group")}
                    options={channelGroups}
                    value={settings.channelGroup}
                    onChange={option =>
                        this.props.onSelectChange(option, 'channelGroup')
                    }
                    className={formStyles.select}
                />
            </div>
        );
    }

    renderByDate(settings) {
        return (
            <div className={classNames(styles.radioButtonContent)}>
                <DateRangePicker
                    className={styles.dateRangePicker}
                    onChange={this.props.onDateRangeChange}
                    value={settings.dateRange}
                    // className="datepicker-full-width"
                />
            </div>
        );
    }

    renderLastX(settings) {
        return <DateRangePicker
            // className={styles.dateRangePicker}
            className={classNames(formStyles.select, styles.selectables, styles.lastX)}
            onChange={option => {
                this.props.onSelectChange({ label: option.number, value: option.number }, 'lastXNumber')
                this.props.onSelectChange(option.unit, 'lastXUnit')
            }}
            value={{
                number: settings.lastXNumber.value,
                unit: settings.lastXUnit,
            }}
            lastX={true}
            lastXStyle={{marginInlineStart: '0.2rem', marginBlockEnd:0, zIndex:9001, position:'relative'}}
            lastXOnly={true}
            archive={true}
            allowClear={false}
        />
        // let placeholderLastX = this.t("Number of") + " " + settings.lastXUnit.label;
        // let units = _.map([this.t('days'), this.t('weeks'), this.t('months')], (e) => {
        //     return { label: e, value: e };
        // });
        // let numbers = _.map(_.range(1, 10), (e) => {
        //     return { label: e, value: e };
        // });

        // return (
        //     <div className={classNames(styles.radioButtonContent)}>
        //         <Select
        //             placeholder={placeholderLastX}
        //             options={numbers}
        //             value={settings.lastXNumber}
        //             onChange={option =>
        //                 this.props.onSelectChange(option, 'lastXNumber')
        //             }
        //             className={classNames(formStyles.select, styles.selectables, styles.lastX)}
        //         />
        //         <Select
        //             options={units}
        //             value={settings.lastXUnit}
        //             onChange={option =>
        //                 this.props.onSelectChange(option, 'lastXUnit')
        //             }
        //             className={classNames(formStyles.select, styles.selectables, styles.lastX)}
        //         />
        //     </div>
        // );
    }
        
    render() {
        const settings = this.props.settings;
        const channelGroups = _.map(this.props.channelGroups, (c) => {
            return { label: c.title, value: c.id }
        });
        
        return (
            <div>
                <div className={styles.category}>{this.t('Time')}</div>
                <div className={styles.radioButtons}>
                    <label>
                        <input type='radio' name='time'
                            value='date'
                            checked={settings.timeAccordionExpanded === 'date'}
                            onChange={(e) => this.props.onSelectChange(e.target.value, 'timeAccordionExpanded')}
                        />
                        { this.t('by date') }
                    </label>
                    { settings.timeAccordionExpanded === 'date'
                        ? this.renderByDate(settings)
                        : null
                    }

                    <label>
                        <input type='radio' name='time'
                            value='lastx'
                            checked={settings.timeAccordionExpanded === 'lastx'}
                            onChange={(e) => this.props.onSelectChange(e.target.value, 'timeAccordionExpanded')}
                        />
                        { this.t('date range') }
                    </label>
                    { settings.timeAccordionExpanded === 'lastx'
                        ? this.renderLastX(settings)
                        : null
                    }
                </div>

                <div className={styles.category}>{this.t('Scope')}</div>
                <div className={[
                    styles.radioButtons,
                    this.props.scopeDisabled ? styles.disabled : ''
                ].join(' ')}>
                    <label>
                        <input type='radio' name='scope'
                            disabled={this.props.scopeDisabled}
                            value='channels'
                            checked={settings.scopeAccordionExpanded === 'channels'}
                            onChange={(e) => this.props.onSelectChange(e.target.value, 'scopeAccordionExpanded')}
                        />
                        { this.t('by channels') }
                    </label>
                    { settings.scopeAccordionExpanded === 'channels'
                        ? this.renderByChannel()
                        : null
                    }

                    <label>
                        <input type='radio' name='scope'
                            disabled={this.props.scopeDisabled}
                            value='channelgroup'
                            checked={settings.scopeAccordionExpanded === 'channelgroup'}
                            data-testid="search-scope-by-channelgroup-input"
                            onChange={(e) => {this.props.onSelectChange(e.target.value, 'scopeAccordionExpanded')}}
                        />
                        { this.t('by channel group') }
                    </label>
                    { settings.scopeAccordionExpanded === 'channelgroup'
                        ? this.renderByChannelGroup(settings, channelGroups, this.props.scopeDisabled)
                        : null
                    }
                </div>


                {/* TODO maybe: replace 'scope' section by single onClick that leads to channel / channelgroup selection view */}
                <div className={styles.category}>{this.t('Sorting by')}</div>
                <div className={classNames(styles.radioButtons, styles.radioButtonsHorizontal)}>
                    <label>
                        <input type='radio' name='category'
                            value='time'
                            checked={settings.rankBy === 'time'}
                            onChange={ (e)=> this.props.onSelectChange(e.target.value, 'rankBy') }
                        />
                        { this.t('time') }
                    </label>

                    <label>
                        <input type='radio' name='category'
                            value='relevance'
                            checked={settings.rankBy === 'relevance'}
                            onChange={ (e)=> this.props.onSelectChange(e.target.value, 'rankBy') }
                        />
                        { this.t('relevance') }
                    </label>
                </div>


                <div className={styles.center}>
                    <Button
                        title={!this.props.query ? this.t('Please enter a query first') : ''}
                        btnClass={styles.center}
                        type='secondary'
                        onClick={() => { this.props.search(false) }}
                        disabled={!this.props.query || this.props.searchDisabled}
                    >
                        {this.t('Search')}
                    </Button>
                </div>
            </div>
        )
    }
}
export default withTranslation()(SearchOptions);