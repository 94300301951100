import { getUsername } from 'src/Widgets/common/helpers';
import GenericCalls from './genericCalls';
import SerialQueue from 'src/common/Queue/SerialQueue';

class APITelemetry {
    static enabled = true;
    // to disable telemetry in dev env: !['development'].includes(process.env.REACT_APP_ENV);
    static disabled_msg = 'telemetry disabled';
    static queue = SerialQueue();

    static async sendIfEnabled(enabled, disabled_msg, call, data) {
        return enabled
            ? await call()
            : data ? console.log('telemtry', data) : null;
    }

    static enhanceData(data) {
        const username = getUsername();
        return {
            component: 'v3',
            ...data,
            username,
            commit: process.env ? process.env.REACT_APP_VERSION : undefined,
        };
    }

    static async post(data) {
        return await this.sendIfEnabled(
            this.enabled,
            this.disabled_msg,
            ()=>this.queue.add(() => GenericCalls.post('/api/telemetry/', this.enhanceData(data), { responseType: 'text', returnResponse: true }, true, false)),
            data
        )
    }

    static async postConsoleError(data) {
        this.sendIfEnabled(
            this.enabled,
            this.disabled_msg,
            ()=>this.queue.add(() => GenericCalls.post('/api/telemetry/console/error', this.enhanceData(data), { responseType: 'text', returnResponse: true }, true, false))
        )
    }

    static async postConsoleWarning(msgString) {
        this.sendIfEnabled(
            this.enabled,
            this.disabled_msg,
            ()=>this.queue.add(() => GenericCalls.post('/api/telemetry/console/warning', this.enhanceData({ msg: msgString }), { responseType: 'text', returnResponse: true }, true, false))
        )
    }
}

export default APITelemetry;
