import { FC, useCallback } from 'react';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import styles from '../TableHeading/TableHeading.module.scss'
import {
    unSelectGroup,
    showAllChannels,
    selectChannels,
    searchChannels,
    setFilterValues,
    toggleFilter,
    setFilterColorValues,
    toggleSelectedButtonStatus,
    setIsChannelDeselected,
} from 'src/redux/actions/actions.channelGroupsWidget';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { ChannelButtonsProps } from './ChannelButtons.types';
import { useAppSelector } from 'src/redux/hooks';
import { trackEvent } from 'src/Widgets/common/helpers';

const ChannelButtons:FC<ChannelButtonsProps> = ({ setIsOpen, setButtonType, allChannelsCount, channelsWillRemovedInGroup, isChannelGroupsWidget }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobileOnTablet = useIsScreenSize(1280)

    const { 
        selectedChannels, 
        selectedButtonStatus, 
        headersLength, 
        filterValuesArray, 
        allChannelsShown, 
        selectedGroupId, 
        searchedChannels, 
        //selectedGroupChannels,
        allChannels
    } = useAppSelector((state: any) => ({
        ...state.channelGroupsWidgetReducer,
        allChannels: state.channelReducers
    }));

    const matomo = useAppSelector((state) => state.matomo);

    const handleButtonType = useCallback((type: string) => {
        setButtonType(type);
        setIsOpen(true);
    }, [setButtonType, setIsOpen]);

    const dispatchActions = (actions: any) => {
        actions.forEach((action: any) => dispatch(action));
    }

    const handleChannelActions = (selectedButtonStatus: boolean) => {
        const initialColorValues = new Array(headersLength).fill('#000000');
        const actions = [
            selectedButtonStatus ? toggleSelectedButtonStatus(true) : unSelectGroup(),
            setFilterValues([]),
            toggleFilter(false),
            setFilterColorValues(initialColorValues),
        ]
        if (!selectedButtonStatus) {
            actions.push(showAllChannels(true), selectChannels(selectedChannels), searchChannels(allChannels?.data))
        }
        dispatchActions(actions);
    }

    const deselectAllHandler = () => {
        dispatch(setIsChannelDeselected(true))
        dispatch(selectChannels([]))
        trackEvent(matomo, 'Channel Groups', 'Deselect All', 'Deselect All Button Clicked');
    }

    return (
        <div className={styles.buttonSection}>
             <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={deselectAllHandler}
                disabled={!selectedChannels?.length}
            >
                {t('Deselect All')}
            </Button>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                data-testid="cgShowSelected"
                onClick={() => {
                    handleChannelActions(true)
                    trackEvent(matomo, 'Channel Groups', 'Show Selected', 'Show Selected Button Clicked');
                }}
                disabled={selectedButtonStatus || (!selectedChannels?.length || searchedChannels?.includes(""))}
            >
                {t('Show Selected')}
            </Button>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                data-testid="cgShowAllButton"
                onClick={() => {
                    handleChannelActions(false)
                    trackEvent(matomo, 'Channel Groups', 'Show All', 'Show All Button Clicked');
                }}
                disabled={!(filterValuesArray?.length || !allChannelsShown || selectedButtonStatus || selectedGroupId) || filterValuesArray?.length === allChannelsCount || (searchedChannels?.some((item: object) => Object.keys(item).length === 0) && filterValuesArray?.includes(""))}
            >
                {t('Show All')}
            </Button>
            {isChannelGroupsWidget && <Button
                data-testid="cgAddChannelsButton"
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={() => handleButtonType('add')}
                disabled={(!selectedChannels?.length)}
            >
                {t('Add')}
            </Button>}
            {isChannelGroupsWidget && <Button
                data-testid="cgDeleteChannelsButton"
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={() => handleButtonType('remove')}
                disabled={(!selectedChannels?.length || allChannelsShown || !channelsWillRemovedInGroup?.length)}
            >
                {t('Delete')}
            </Button>}
        </div>
    );
}

export default ChannelButtons;
