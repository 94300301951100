import React from 'react';
import './User.scss';
import detailLayout from '../../basicStyles/detailLayout.module.scss';
import LoadingSpinner from '../../basicElements/LoadingSpinner/LoadingSpinner';
import TextInput from '../../basicElements/TextInput/TextInput';
import DatePicker from 'react-date-picker';
import formStyles from '../../basicStyles/forms.module.scss';
import _ from 'lodash';
import { getSelectOptionsFromArr, dateToTimestamp, handleAsync } from '../../helpers.js';
import APIUsers from '../../../../API/APIUsers';
import DeleteRow from '../../basicElements/DeleteRow/DeleteRow';
import FormFooter from '../../basicElements/FormFooter/FormFooter';
import { withTranslation } from 'react-i18next';
import APIOptions from '../../../../API/APIOptions';
import LabeledSelect from '../../basicElements/LabeledSelect/LabeledSelect';
import Collapsible from '../../basicElements/Collapsible/Collapsible';
import { connect } from 'react-redux';
import { getAdminGroups as getChannelGroups } from '../../../../redux/actions/actions.channelGroups';
import APICompanyGroups from 'src/API/APICompanyGroups';
import Popup from '../../basicElements/Popup/Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import APIFeedGroups from 'src/API/APIFeedGroups';
// import SwitchGroup from '../../basicElements/SwitchElements/SwitchGroup';
// import LabeledSwitch from '../../basicElements/SwitchElements/LabeledSwitch';
// import InfoIcon from '../../basicElements/InfoIcon/InfoIcon';

class User extends React.Component {
    constructor(props) {
        super(props);

        const user = {
            id: undefined,
            username: '',
            company: undefined,
            expiryTS: undefined,
            archiveDays: 30,
            can_AVE: 0,
            can_Analytics: 0,
            can_AutoSummarize: 0,
            ed_allowEmptySave: 0,
            isUserManager: 0,
            comment: '',
            channelGroup: undefined,
            shareGroups: [],
            created: undefined,
            type: undefined,
            emailLang: undefined,
            maxClipLength: 1800
            // updatedFeedGroups:[],
            // initFeedGroups:[],
        };

        this.state = {
            timeoutId: null,
            user,
            edited: false,
            isDatePicker: 'date',
            update: this.props.update,
            saveSuccessful: false,
            saveFailed: false,
            channelGroupOptions: [],
            editable: false,
            errorMessage: ''
        };

        this.save = this.save.bind(this);
        this.onTextfieldChange = this.onTextfieldChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.delete = this.delete.bind(this);
        this.displayMessageTemporarily = this.displayMessageTemporarily.bind(this);
        this.onArchiveToggleChange = this.onArchiveToggleChange.bind(this);
        this.onArchiveDateChange = this.onArchiveDateChange.bind(this);
        this.onArchiveTextfieldChange = this.onArchiveTextfieldChange.bind(this);
        // this.onFeedGroupsChange = this.onFeedGroupsChange.bind(this);
    }

    displayMessageTemporarily() {
        const timeoutId = setTimeout(() => {
            this.setState({
                saveSuccessful: false,
                saveFailed: false
            });
        }, 5000);
        this.setState({ timeoutId });
    }

    onArchiveToggleChange = (e) => {
        this.setState({ isDatePicker: e.target.value });
    };

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId);
    }

    // TODO: error catching
    async save(params) {
        if (!this.state.edited) return;

        // if (user.comment && user.comment.length >= 199) {
        //     this.setState({ errorMessage: t('Comment is too long') });
        //     return;
        // }

        let calls = [];
        let user = _.cloneDeep(this.state.user);

        const { username, company, type, emailLang, timezone, channelGroupId, archiveDays, maxClipLength, expiryTS } = user;
        const isRequiredFilled = username && company && type && emailLang && timezone && channelGroupId && archiveDays && expiryTS && maxClipLength;
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username);

        if (!isEmailValid && !this.state.update) {
            this.setState({
                saveFailed: true,
                errorMessage: 'Please enter a valid email address for username.'
            });
            this.displayMessageTemporarily();
            return;
        }

        // if (!isRequiredFilled && !this.state.update) {
        //     this.setState({
        //         saveFailed: true,
        //         errorMessage: 'Please fill in all required fields. (marked with *)'
        //     });
        //     this.displayMessageTemporarily();
        //     return;
        // }

        if(!isRequiredFilled && user.archiveDays === null || user.archiveDays === undefined || user.archiveDays === ''){
            this.setState({
                saveFailed: true,
                errorMessage: 'Please fill in all required fields. (marked with *)'
            });
            this.displayMessageTemporarily();
            return;
        } 

        if(!isRequiredFilled && user.maxClipLength === null || user.maxClipLength === undefined || user.maxClipLength === ''){
            this.setState({
                saveFailed: true,
                errorMessage: 'Please fill in all required fields. (marked with *)'
            });
            this.displayMessageTemporarily();
            return;
        } 

        if (!isRequiredFilled && user.expiryTS === null || user.expiryTS === undefined || user.expiryTS === '') {
            this.setState({
                saveFailed: true,
                errorMessage: 'Expiry date is required and cannot be empty.'
            });
            this.displayMessageTemporarily();
            return;
        }


        // let feedGroups = {add:[], remove:[]};
        // user.updatedFeedGroups.map((i) => i.inGroup ? feedGroups.add.push(i.id) : feedGroups.remove.push(i.id));
        // delete user.feedGroups;
        // delete user.updatedFeedGroups;
        // const saveFeedGroups = async (fgs, userId)=>{
        //     userId = userId !== undefined ? userId : this.state.user.id;
        //     if (fgs.add.length > 0) {
        //         calls.push(
        //             fgs.add.map(async (fg)=> await APIFeedGroups.addUsers(userId,fg))
        //         );
        //     }
        //     if (fgs.remove.length > 0) {
        //         fgs.remove.map(async (fg)=> await APIFeedGroups.removeUsers(userId,fg))
        //     }
        //  }

        //delete unnecessary fields
        delete user.created;
        delete user.userGroupName;
        delete user.shareGroups;

        if (this.state.update) {
            calls.push(
                await APIUsers.put(user.id, user, true).then((res) => {
                    if (res.rc === 0) {
                        this.setState(
                            {
                                edited: false,
                                saveSuccessful: true,
                                saveFailed: false,
                                user: user,
                                initialUser: _.cloneDeep(user)
                            },
                            async () => {
                                this.displayMessageTemporarily();
                                await this._componentDidMount();
                            }
                        );
                    } else {
                        this.setState(
                            {
                                edited: false,
                                saveSuccessful: false,
                                saveFailed: true,
                                errorMessage: res.data.msg,
                                user: _.cloneDeep(this.state.initialUser)
                            },
                            this.displayMessageTemporarily
                        );
                    }
                })
            );
            // await saveFeedGroups(feedGroups);
            // this.setState((s)=>{ s.user.updatedFeedGroups = []; return s; });
        } else {
            const newUser = calls.push(
                await APIUsers.post(user, true).then((res) => {
                    if (res.rc === 0) {
                        this.setState((s) => {
                            // s.user.id = newUser.id;
                            s.user.created = Date.now() / 1000;
                            // s.user.updatedFeedGroups = [];
                            return {
                                update: true,
                                user: {
                                    ...s.user,
                                    id: res.data.id
                                },
                                edited: false,
                                saveSuccessful: true,
                                saveFailed: false,
                                initialUser: _.cloneDeep(s.user)
                            };
                        }, this.displayMessageTemporarily);
                    } else {
                        this.setState(
                            {
                                edited: false,
                                saveSuccessful: false,
                                saveFailed: true,
                                errorMessage: res.data.msg
                            },
                            this.displayMessageTemporarily
                        );
                    }
                    return res.data;
                })
            );
            // await saveFeedGroups(feedGroups, newUser.id);
        }
    }

    async delete() {
        await APIUsers.delete(this.state.user.id);
        this.props.onGoBack(true);
    }

    componentDidUpdate(props, state) {
        if (props.channelGroups !== this.props.channelGroups) {
            const channelGroupOptions = _.map(this.props.channelGroups, (cg) => ({
                label: cg.title,
                value: cg.id
            }));
            this.setState({ channelGroupOptions });
        }
    }

    async componentDidMount() {
        return await handleAsync(this._componentDidMount());
    }

    // async fetchFeedGroups(){
    //     let feedGroups = (await APIFeedGroups.get()).data;
    //     feedGroups = await Promise.all(feedGroups.map(async (fg)=>{    // fetch user details to allow showing name in dropdown
    //         const u = (await APIUsers.get(fg.id)).data;
    //         return u;
    //     }));
    //     return feedGroups;
    // }

    // mapFeedGroups() {
    //     const feedGroups = _.map(this.state.user.feedGroups, g => {
    //         let potentialGroup = _.find(this.availableFeedGroups, {
    //             value: g
    //         });

    //         if (!potentialGroup) return null;

    //         return {
    //             label: potentialGroup.label,
    //             value: g
    //         };
    //     });

    //     return _.filter(feedGroups, g => g !== null);
    // }

    async _componentDidMount() {
        const timezones = (await APIOptions.getTimezones()).data;
        this.timezoneOptions = _.map(timezones, (t) => ({ label: t.title, value: t.id }));

        const companies = (
            await Promise.all(
                this.props.me.managedCompanyGroups.map(async (cg) => {
                    const res = (await APICompanyGroups.get(cg)).data;
                    return res.companies;
                })
            )
        ).reduce((sum, i) => sum.concat(i), []);
        this.companyOptions = [];
        for (let i = 0; i < companies.length; ++i) {
            const c = companies[i];
            this.companyOptions.push({ label: c.title, value: c.id });
        }

        this.props.getChannelGroups();

        // const feedGroups = await this.fetchFeedGroups();
        // this.availableFeedGroups = _.map(feedGroups, g => ({
        //     label: g.username,
        //     value: g.id
        // }));

        let user;
        if (this.state.update) {
            user = (await APIUsers.get(this.props.id || this.state.user.id)).data;
            if (user) {
                Object.keys(user).forEach((key) => {
                    if (key !== 'archiveDays' && user[key] == null) {
                        delete user[key];
                    }
                });
    
                // const resFeedGroups = await APIUsers.getFeedGroups(this.props.id);
                // if(resFeedGroups?.rc === 0){
                //     user.feedGroups = resFeedGroups.data.map(fg => fg.id);
                // }
                // user.initFeedGroups = _.cloneDeep(user.feedGroups);
            }
        } else {
            user = this.state.user;
            user.company = this.props.groupId || this.props.currentGroup.id;
        }
        // user.updatedFeedGroups = [];

        // TODO (maybe): this just checks for isUserManagerForCompany, without matching companyId
        // HOWEVER: this shouldn't be necessary, as a user should only see the company they are in
        // and the companies they can edit because of isCompanyGroupAdmin
        // so either they can edit all they can see, or they only see the company they are in anyway.

        const isDatePicker = user.archiveDays > 1262296799 ? 'date' : 'number';

        this.setState({
            user: {
                ...user
            },
            isDatePicker,
            editable:
                this.props.me.isUserManagerForCompany ||
                this.props.me.managedCompanyGroups.length > 0
        });

        //compare initial data with current data only if its an update
        this.setState({
            user,
            isDatePicker,
            initialUser: _.cloneDeep(user), // store initial data for comparison
            editable:
                this.props.me.isUserManagerForCompany ||
                this.props.me.managedCompanyGroups.length > 0
        });
    }

    //Check if user has made any changes
    validate() {
        const differences = !_.isEqual(this.state.user, this.state.initialUser);
        this.setState({ edited: differences, saveSuccessful: false, saveFailed: false });
    }

    // onFeedGroupsChange(options, change) {
    //     let obj;
    //     if (change.option !== undefined) obj = { id: change.option.value, inGroup: true };
    //     if (change.removedValue !== undefined) obj = { id: change.removedValue.value, inGroup: false }

    //     this.setState(state => {
    //         let fgs;
    //         if (change.action === 'clear') {
    //             state.user.feedGroups = [];
    //             state.user.updatedFeedGroups = _.map(state.user.initFeedGroups, (fg) => ({ id: fg, inGroup: false }));
    //         } else {
    //             fgs = state.user.updatedFeedGroups;
    //             state.user.feedGroups = _.map(options, o => o.value);
    //             let existing = _.find(fgs, { id: obj.id });
    //             existing ? existing.inGroup = obj.inGroup : fgs.push(obj);
    //         }
    //         return {
    //             user: state.user,
    //         };
    //     });

    //     this.validate();
    // }
    onArchiveDateChange(e) {
        const date = new Date(e);
        const archiveDays = Math.floor(date.getTime() / 1000);
        this.setState(
            (prevState) => ({
                user: {
                    ...prevState.user,
                    archiveDays: archiveDays
                }
            }),
            this.validate
        );
    }

    onArchiveTextfieldChange = (event) => {
        let archiveDays = event.target.value;


        this.setState(
            (prevState) => ({
                user: {
                    ...prevState.user,
                    archiveDays
                }
            }),
            this.validate
        );
    };

    onTextfieldChange(event, key) {
        const val = event.target.value;

        this.setState((state) => {
            state.user[key] = val;
            return { user: state.user };
        }, this.validate);
    }

    onDateChange(date, key) {
        // If the date is null (i.e., the date picker is cleared), set the state to undefined.
        const timestamp = date && date instanceof Date ? dateToTimestamp(date) : null;

        this.setState((state) => {
            state.user[key] = timestamp;
            return { user: state.user };
        }, this.validate);
    }

    onSelectChange(option, key) {
        this.setState((state) => {
            state.user[key] = option.value;

            return { user: state.user };
        }, this.validate);
    }

    render() {
        if (!this.state.user) {
            return <LoadingSpinner />;
        }

        const user = this.state.user;
        const typeOptions = getSelectOptionsFromArr([
            'internal',
            'eval',
            'project',
            'retainer'
        ]);
        const typeSelected = _.find(typeOptions, {
            value: user.type
        });
        const languageOptions = getSelectOptionsFromArr(['en', 'es', 'de', 'fr', 'ar']);
        const languageSelected = _.find(languageOptions, {
            value: user.emailLang
        });

        const companySelected = _.find(this.companyOptions, {
            value: user.company || this.props.currentGroup.id
        });
        const timezoneSelected = _.find(this.timezoneOptions, {
            value: user.timezone
        });
        const channelGroupSelected = _.find(this.state.channelGroupOptions, {
            value: user.channelGroupId
        });

        const createdDate = new Date(user.created * 1000).toString();
        const expiryTS =
            typeof user.expiryTS === 'number'
                ? new Date(user.expiryTS * 1000)
                : undefined;

        const editable = this.state.editable;

        // translation method with fallback
        const t = this.props.t || ((k) => k);
        //const isCompanyManager = this.props.me.managedCompanyGroups.length > 0
        return (
            <div className={detailLayout.wrapper}>
                {this.state.saveFailed === true && (
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            this.setState({ saveFailed: false });
                        }}
                    >
                        <p>{this.state.errorMessage}</p>
                    </Popup>
                )}
                <div className={detailLayout.view}>
                    <FormFooter
                        onGoBack={this.props.onGoBack}
                        onAction={this.save}
                        actionDisabled={
                            !this.state.edited ||
                            (user.comment && user.comment.length >= 199) ||
                            !user.expiryTS ||
                            !user.type ||
                            !user.emailLang ||
                            !user.maxClipLength ||
                            !user.archiveDays ||
                            !user.channelGroupId
                        }
                        actionLabel={
                            this.state.update ? t('Save Changes') : t('Create User')
                        }
                        success={
                            this.state.saveSuccessful ? t('Successfully saved') : null
                        }
                        fail={this.state.saveFailed ? t('Could not save') : null}
                    />
                    <TextInput
                        value={user.username}
                        onChange={(event) => this.onTextfieldChange(event, 'username')}
                        label={t('Username')}
                        placeholder={t('Add Username')}
                        disabled={!editable || this.state.update}
                        isRequired
                        invalid={
                            user.username &&
                            !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(
                                user.username
                            ) &&
                            !this.state.update
                        }
                        style={{ zIndex: 0 }}
                    />
                    {user.username &&
                        !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(user.username) &&
                        !this.state.update && (
                            <p className="error-message">
                                {t('Username must be a valid email address')}
                            </p>
                        )}

                    {this.props.me.isUserManagerForCompany !== 1 && (
                        <LabeledSelect
                            label={t('Company')}
                            placeholder={t('Select Company')}
                            options={this.companyOptions}
                            value={companySelected}
                            onChange={(option) => this.onSelectChange(option, 'company')}
                            className={formStyles.select}
                            isDisabled={!editable || !this.state.update}
                            isRequired
                            style={{ zIndex: 12 }}
                        />
                    )}

                    {this.state.update && (
                        <TextInput
                            value={createdDate}
                            label={t('Created')}
                            disabled={true}
                            onChange={() => {}}
                            style={{ zIndex: 11 }}
                        />
                    )}
                    <div className="user-datepicker-with-label" style={{ zIndex: 11 }}>
                        {t('Expiry') + ' *'}
                        {': '}
                        <DatePicker
                            onChange={(e) => this.onDateChange(e, 'expiryTS')}
                            value={expiryTS}
                            className="user-datepicker"
                            disabled={!editable}
                        />
                    </div>

                    <LabeledSelect
                        label={t('Type')}
                        placeholder={t('Select Type')}
                        options={typeOptions}
                        value={typeSelected}
                        onChange={(option) => this.onSelectChange(option, 'type')}
                        className={formStyles.select}
                        isDisabled={!editable}
                        isRequired
                        style={{ zIndex: 10 }}
                    />

                    <div className="user-comment">
                        {user.comment && user.comment.length >= 199 && (
                            <p className="error-message-comment">
                                {t('Comment is too long')}
                            </p>
                        )}
                        <TextInput
                            value={user.comment}
                            onChange={(event) => this.onTextfieldChange(event, 'comment')}
                            label={t('Comment')}
                            placeholder={t('Add Comment')}
                            disabled={!editable}
                            style={{ zIndex: 9 }}
                            maxLength={199}
                            invalid={user.comment && user.comment.length >= 199}
                        />
                    </div>

                    <LabeledSelect
                        label={t('Email Language')}
                        placeholder={t('Select Email Language')}
                        options={languageOptions}
                        value={languageSelected}
                        onChange={(option) => this.onSelectChange(option, 'emailLang')}
                        className={formStyles.select}
                        isDisabled={!editable}
                        isRequired
                        style={{ zIndex: 8 }}
                    />

                    <LabeledSelect
                        label={t('Timezone')}
                        placeholder={t('Select Timezone')}
                        options={this.timezoneOptions}
                        value={timezoneSelected}
                        onChange={(option) => this.onSelectChange(option, 'timezone')}
                        className={formStyles.select}
                        isDisabled={!editable}
                        isRequired
                        style={{ zIndex: 7 }}
                    />

                    <div className="user-datepicker-archive" style={{ zIndex: 6 }}>
                        <div className="user-archive-container">
                            <input
                                onChange={this.onArchiveToggleChange}
                                type="radio"
                                value="date"
                                name="archive-date"
                                id="archive-date"
                                checked={this.state.isDatePicker === 'date'}
                            />
                            <label htmlFor="archive-date">{t('Start Date')}</label>
                            <input
                                onChange={this.onArchiveToggleChange}
                                type="radio"
                                value="number"
                                name="archive-number"
                                id="archive-number"
                                checked={this.state.isDatePicker === 'number'}
                            />
                            <label htmlFor="archive-number">{t('Number of Days')}</label>
                        </div>
                        {this.state.isDatePicker === 'date' ? (
                            <div style={{ zIndex: '10' }}>
                                <DatePicker
                                    onChange={(e) => this.onArchiveDateChange(e)}
                                    value={
                                        this.state.user.archiveDays < 1262296799
                                            ? null
                                            : new Date(this.state.user.archiveDays * 1000)
                                    }
                                    className="user-datepicker"
                                    disabled={!editable}
                                    maxDate={new Date()}
                                />
                            </div>
                        ) : (
                            <TextInput
                                id="archiveDays"
                                placeholder={t('Archive Days')}
                                value={
                                    user.archiveDays > 1262296799
                                        ? null
                                        : user.archiveDays
                                }
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (value === '' || !isNaN(value)) {
                                        this.onArchiveTextfieldChange(event);
                                    }
                                }}
                                onKeyPress={(event) => {
                                    const keyCode = event.keyCode || event.which;
                                    const keyValue = String.fromCharCode(keyCode);
                                    if (!/\d/.test(keyValue)) {
                                        event.preventDefault();
                                    }
                                }}
                                isRequired
                                invalid={
                                    user.archiveDays === null ||
                                    user.archiveDays === undefined ||
                                    user.archiveDays === ''
                                }
                                data-testid="archiveDays"
                                className="archiveDays-input"
                                numbersOnly
                                label={t('Archive Days')}
                                style={{ zIndex: 4 }}
                            />
                        )}
                    </div>

                    <TextInput
                        value={user.maxClipLength}
                        onChange={(event) =>
                            this.onTextfieldChange(event, 'maxClipLength')
                        }
                        label={t('Max Clip Length')}
                        placeholder={t('Add Max Clip Length')}
                        disabled={!editable}
                        invalid={
                            user.maxClipLength === null ||
                            user.maxClipLength === undefined ||
                            user.maxClipLength === ''
                        }
                        isRequired
                        numbersOnly
                        style={{ zIndex: 3 }}
                    />

                    <LabeledSelect
                        label={t('Channel Group')}
                        placeholder={t('Select Channel Group')}
                        options={this.state.channelGroupOptions}
                        value={channelGroupSelected}
                        onChange={(option) =>
                            this.onSelectChange(option, 'channelGroupId')
                        }
                        className={formStyles.select}
                        isDisabled={!editable}
                        isRequired
                        style={{ zIndex: 2 }}
                    />

                    {/* <SwitchGroup>
                        <LabeledSwitch
                            checked={user.isFeedGroup}
                            onChange={(val) => { this.setState((s) => { s.user.isFeedGroup = val; return s; }); this.validate();}}
                            className={formStyles.checkboxItem}
                        >
                            {t('Receive feed results from others')}
                            <InfoIcon title={t('enable_feed_group_info')} />
                        </LabeledSwitch>
                    </SwitchGroup>

                    <LabeledSelect
                        label={t('Providing feed results to')}
                        placeholder={t('Select Feed Groups')}
                        options={this.availableFeedGroups}
                        value={this.mapFeedGroups()}
                        onChange={this.onFeedGroupsChange}
                        isMulti
                        className={formStyles.select}
                        info={t('user_feed_groups_info')}
                        style={{zIndex: 1}}
                    /> */}

                    <div className="checkboxes-container">
                        {(Number(this.props.me.can_AVE) === 1) && (
                            <div className="checkboxItem">
                                <input
                                    type="checkbox"
                                    id="canAVE"
                                    name="canAVE"
                                    value={Number(this.state.user.can_AVE)}
                                    checked={Number(this.state.user.can_AVE)}
                                    onChange={(e) => {
                                        this.setState(
                                            (prevState) => ({
                                                user: {
                                                    ...prevState.user,
                                                    can_AVE: e.target.checked ? 1 : 0
                                                }
                                            }),
                                            this.validate
                                        );
                                    }}
                                />
                                <label htmlFor="canAVE">{t('Show AVE values')}</label>
                            </div>
                        )}
                        {(Number(this.props.me.can_Analytics) === 1) && (
                            <div className="checkboxItem">
                                <input
                                    type="checkbox"
                                    id="canShowAnalytics"
                                    name="canShowAnalytics"
                                    value={Number(this.state.user.can_Analytics)}
                                    checked={Number(this.state.user.can_Analytics)}
                                    onChange={(e) => {
                                        this.setState(
                                            (prevState) => ({
                                                user: {
                                                    ...prevState.user,
                                                    can_Analytics: e.target.checked
                                                        ? 1
                                                        : 0
                                                }
                                            }),
                                            this.validate
                                        );
                                    }}
                                />

                                <label htmlFor="canShowAnalytics">
                                    {t('Allow Analytics Widgets')}
                                </label>
                            </div>
                        )}
                        {(Number(this.props.me.can_AutoSummarize) === 1) && (
                            <div className="checkboxItem">
                                <input
                                    type="checkbox"
                                    id="canAutoSummarize"
                                    name="canAutoSummarize"
                                    value={Number(this.state.user.can_AutoSummarize)}
                                    checked={Number(this.state.user.can_AutoSummarize)}
                                    onChange={(e) => {
                                        this.setState(
                                            (prevState) => ({
                                                user: {
                                                    ...prevState.user,
                                                    can_AutoSummarize: e.target.checked
                                                        ? 1
                                                        : 0
                                                }
                                            }),
                                            this.validate
                                        );
                                    }}
                                />

                                <label htmlFor="canAutoSummarize">
                                    {t('Allow auto summaries for profile results')}
                                </label>
                            </div>
                        )}
                        <div className="checkboxItem">
                            <input
                                type="checkbox"
                                id="ed_allowEmptySave"
                                name="ed_allowEmptySave"
                                value={Number(this.state.user.ed_allowEmptySave)}
                                checked={Number(this.state.user.ed_allowEmptySave)}
                                onChange={(e) => {
                                    this.setState(
                                        (prevState) => ({
                                            user: {
                                                ...prevState.user,
                                                ed_allowEmptySave: e.target.checked
                                                    ? 1
                                                    : 0
                                            }
                                        }),
                                        this.validate
                                    );
                                }}
                            />

                            <label htmlFor="ed_allowEmptySave">
                                {t('Allow Empty Save in Editorial')}
                            </label>
                        </div>
                        {(Number(this.props.me.isUserManagerForCompany) === 1) && (
                            <div className="checkboxItem">
                                <input
                                    type="checkbox"
                                    id="isUserManager"
                                    name="isUserManager"
                                    value={Number(this.state.user.isUserManager)}
                                    checked={Number(this.state.user.isUserManager)}
                                    onChange={(e) => {
                                        this.setState(
                                            (prevState) => ({
                                                user: {
                                                    ...prevState.user,
                                                    isUserManager: e.target.checked
                                                        ? 1
                                                        : 0
                                                }
                                            }),
                                            this.validate
                                        );
                                    }}
                                />

                                <label
                                    htmlFor="isUserManager"
                                    style={{
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center'
                                    }}
                                >
                                    {t('User Manager')}
                                    <FontAwesomeIcon
                                        icon="info-circle"
                                        title={t(
                                            'this user can add/modify/delete all users in this company'
                                        )}
                                    />
                                </label>
                            </div>
                        )}
                    </div>

                    {this.state.update &&
                    editable &&
                    (!this.props.me.managedCompanyGroups ||
                        this.props.me.managedCompanyGroups.length !== 0) ? (
                        <Collapsible closed={t('Show More')} open={t('Hide')}>
                            <DeleteRow
                                labelText={t('Remove User')}
                                confirmationText={t(
                                    'Really delete user?' + ' "' + user.username + '"'
                                )}
                                onClick={this.delete}
                                deletePopup={true}
                            />
                        </Collapsible>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    me: state.me,
    channelGroups: state.channelGroupReducers
});
export default withTranslation()(connect(mapStateToProps, { getChannelGroups })(User));
