import React from 'react';
import styles from './WaterfallOptions.module.scss';
import Slider from '../../common/basicElements/Slider/Slider';
import { withTranslation } from 'react-i18next';
import { dateToTimeString, dateToString, dateIsToday } from '../../common/helpers';

class WaterfallOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };        
    }

    render() {
        const {
            t, speed, step, inputRef, onSpeedChange,
            onStepChange, numberOfSteps, avgTime
        } = this.props;
        const minLabel = speed === 0 ? 'stop' : 'slower';

        return(
            <div className={styles.sliderWrapper}>
                <Slider
                    inputRef={inputRef}
                    onSliderChange={onSpeedChange}
                    value={speed}
                    min="0" max="10"
                    minLabel={t(minLabel)} maxLabel={t("faster")}
                    valueLabel={false}
                />                
                <Slider
                    disabled={numberOfSteps === 0}
                    onSliderChange={onStepChange}
                    value={step}
                    min="1" max={numberOfSteps}
                    showArrows={true}
                    valueLabel={false}
                />
                {
                    avgTime ?
                        <div className={styles.avgTime}>
                        ~
                            {
                                dateIsToday(avgTime) ?
                                    null
                                :
                                    dateToString(avgTime) + ' '
                            }
                            {dateToTimeString(avgTime)}
                        </div>
                    : null
                }
            </div>
        );
    }
}
export default withTranslation()(WaterfallOptions);