import { FC } from 'react'
import { GroupItemProps } from './GroupItem.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import styles from './GroupItem.module.scss'
import classNames from 'classnames';

const GroupItem:FC<GroupItemProps> = ({groupData, selectGroupHandler, selectedGroup, setIsEditViewOpen, isAdminGroup}) => {

    const {title, id} = groupData;

    const truncatedText = title?.length > 20 ? title?.slice(0, 20) + '...' : title;
    return (
        <div
            onClick={() => selectGroupHandler(groupData)}
            title={title}
            aria-label={`channelGroup-${id}`}
            className={classNames(
                styles.groupCard,
                id === selectedGroup.id && styles.selectedGroup
            )}
            
        >
            <div data-testid={`edit-icon-${title}`} className={styles.penIcon}>
            <FontAwesomeIcon
                onClick={(e: any) => {
                    e.stopPropagation()
                    selectGroupHandler(groupData)
                    setIsEditViewOpen(true)
                }}
                
                icon={faPen}
            />
            </div>
            {truncatedText}
        </div>
    );
}

export default GroupItem