import { RECEIVE_ITEMS, UPDATED_ITEM, ADDED_GROUP, DELETED_GROUP,
    ADDED_CHANNELS, ADDED_CHANNELGROUPS, REMOVED_CHANNELGROUPS, REMOVED_CHANNELS, SELECT_GROUP
} from '../types/actionTypes.channelGroups';
import _ from 'lodash';

const channelGroupReducers = (state = [], action) => {
    let idx, rv, input
    switch (action.type) {
        case RECEIVE_ITEMS:
            return action.data;
        case UPDATED_ITEM:
            return updated_item(state, action);
        case ADDED_CHANNELS:
            rv = _.cloneDeep(state);
            input = _.cloneDeep(action)
            idx = _.findIndex(rv,{id: input.id});
            input.data.map((i)=>{ i.title = i.name; delete i.name; return undefined; });
            rv[idx].channels = rv[idx].channels.concat(input.data);
            return rv;
        case REMOVED_CHANNELS:
            rv = _.cloneDeep(state);
            input = _.cloneDeep(action)
            idx = _.findIndex(rv,{id: input.id});
            if(idx >= 0){
                input.data.map((i)=>{ _.remove(rv[idx].channels,{id:i.id}); return undefined; });
            }
            return rv;
        case ADDED_CHANNELGROUPS:
            idx = _.findIndex(state,{id: action.id});
            state[idx].channelGroups = state[idx].channelGroups.concat(
                _.map(action.data,(i)=>i.id)
            );
            return state;
        case REMOVED_CHANNELGROUPS:
            idx = _.findIndex(state,{id: action.id});
            action.data.map((removed)=>{
                _.remove(state[idx].channelGroups,(i)=>(i === removed.id));
                return undefined;
            });
            return state;
        case ADDED_GROUP:
            return added_group(state, action);
        case DELETED_GROUP:
            return deleted_group(state, action);
        default:
            return state;
    }
};

const updated_item = (state, action) => {
    let groupIndex = _.findIndex(state, { id: action.id });
    let rv = _.cloneDeep(state);
    rv[groupIndex].title = action.data.title || rv[groupIndex].title;
    return rv;
}

const added_group = (state, action) => {
    let rv = _.cloneDeep(state);
    rv.push({
        id: action.id,
        title: action.data.title,
        groups:[],
        channels:[]
    });
    return rv;
}

const deleted_group = (state, action) => {
    let rv = _.cloneDeep(state);
    const index = _.findIndex(rv, { id: action.id });
    rv.splice(index, 1);
    return rv;
}

export default channelGroupReducers;