import React, { Component } from 'react';
import GroupCardsContainer from '../common/GroupCards/GroupCardsContainer';
import _ from 'lodash';
import APIShareGroups from '../../API/APIShareGroups';
// import User from '../common/DetailViews/User/User';
import ShareGroup from '../common/DetailViews/ShareGroup/ShareGroup';
import GroupEdit from '../common/DetailViews/GroupEdit/GroupEdit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popup from '../common/basicElements/Popup/Popup';
import { withTranslation } from 'react-i18next';

class ShareGroupCards extends Component {
    static propTypes = {
        getGroups: PropTypes.func,
        getUsers: PropTypes.func,
        onGoBack: PropTypes.func
    };
    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            errorPopup: {
                isShown: false,
                //message: undefined
            }
        }

        this.buildShareGroupState = this.buildShareGroupState.bind(this);
        this.getGroup = this.getGroup.bind(this);
        this.deleteShareGroup = this.deleteShareGroup.bind(this);
    }

    // rename a few keys for generic cards
    buildShareGroupState(apiShareGroups) {
        let groups = apiShareGroups.map((sg)=>{
            
            let titleTags;
            // decide if we show owner of share group
            if(
                sg.ownerID >= 0 // <0 means: own company is owner, so we don't need to show that
                && (this.props.me?.isUserManagerForCompany || this.props.me?.managedCompanyGroups?.length > 0)  // no need to show for normal users (they only see their own share groups)
            ){
                let username = sg.ownerID === this.props.me?.id
                    ? this.props.me?.name
                    : this.props.users?.find(u=>u.id === sg.ownerID)?.username;
                titleTags = [username ? username : sg.ownerID]
            }
                
            return {...sg, titleTags}
        })
        this.setState({ groups });
        return groups;
    }

    // returns single group in generic format (formally buildGroupState above)
    async getGroup(id, showDetailView) {
        const group = (await APIShareGroups.get(id)).data;
        const { title } = this.state.groups.find(g => g.id === id);

        const formattedGroup = {
            id: id,
            title: title,
            items: _.map(group, u => ({
                id: u.id,
                title: u.username,
                // onClick: () => showDetailView(u.id)
            }))
        }

        return formattedGroup;
    }


    buildListState(apiShareGroup, apiUsers) {        
        return _.map(apiUsers, (c) => {
            let isSelected = _.find(apiShareGroup, {
                id: c.id
            });
            isSelected = isSelected ? true : false;
            
            return {
                id: c.id,
                title: c.username,
                isSelected: isSelected
            }
        });                    
    }

    EditGroupView = props => (
        <GroupEdit
            groupId={props.groupId}
            onGoBack={props.goBack}
            group={props.group}
            updateGroup={APIShareGroups.put}
            deleteGroup={this.deleteShareGroup}
        />
    )

    async deleteShareGroup(groupId) {
        const response = await APIShareGroups.delete(groupId, 30501);
        const responseData = {...response.data};
        if(responseData.rc === 30501){
            //const userCount = (responseData.msg.match(/(\d+) users/) || [])[1];
            this.setState({ errorPopup: { isShown: true } });
        }
    }
    
    render() {
        const t = this.props.t || (k => k);
        const CreateView = props => (
            <ShareGroup                
                groupId={props.groupId}
                onGoBack={props.goBack}
                getUsers={this.props.getUsers}
            />            
        );
        let companyId = undefined;
        if(this.props.companyId){
            companyId = this.props.companyId;
        } else if(this.props.me?.isUserManagerForCompany){
            companyId = this.props.me?.companyid;
        }
        return (
            <>
            {this.state.errorPopup.isShown && <Popup size='auto'
                    blockContent={true} onOk={
                     () => this.setState({ errorPopup: { isShown: false } })   
                    }>
                {t('Cannot delete share group')}
            </Popup>}
            <GroupCardsContainer
                buildGroupState={this.buildShareGroupState}
                createGroup={(sgObj) => APIShareGroups.post({...sgObj, companyid: companyId})}
                updateGroup={APIShareGroups.put}
                deleteGroup={this.deleteShareGroup}
                getGroups={async () => {
                    let sgs = this.props.getGroups ? await this.props.getGroups() : await APIShareGroups.get();
                    sgs.data = await Promise.all(sgs.data.map(async(sg) => {
                        sg.users = (await APIShareGroups.get(sg.id)).data;
                        return sg;
                    }));
                    return sgs;
                }}
                // detailView={DetailView}
                createView={ (this.props.me?.isUserManagerForCompany || this.props.isCompanyManagerWidget) ? CreateView : undefined }
                useGravatar
                editItemIcon
                groupView={this.EditGroupView}
                getGroup={this.getGroup}
                secondaryGoBack={this.props.onGoBack}
            />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        me: state.me,
    }
}

export default connect(mapStateToProps)(withTranslation()(ShareGroupCards));