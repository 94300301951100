import GenericCalls from './genericCalls';
import { timestampToDate, objectToParamsString } from '../Widgets/common/helpers';
import _ from 'lodash';

class APIEpg {
    static convertStatus(statusCode){
        // use statusCode from response but map them to self-explaining strings for better understandability of code
        const codeMappings = {
            A: "available",
            P: "partially-available",
            N: "not-available"
        };
        return { statusCode, status:codeMappings[statusCode] };
    }

    static async getEPGBySearch(searchTerm, from, to, channelIds, channelGroupId) {
        let rv = await GenericCalls.get(
            '/api/epg/search/' + searchTerm + objectToParamsString({from, to, channelIds, channelGroupId})
        );
        rv.data = _.map(rv.data, (i)=>{
            i.available = this.convertStatus(i.available);
            return i;
        });
        return rv;
    }

    static async getEPG(channelId, date, channelName) {
        let rv = await GenericCalls.get(
            `/api/epg?channelId=${channelId}&date=${date}`
        );
        
        if (!rv.data || rv.data.status) return;

        rv.data = _.map(rv.data, (epgEntry) => {
            if (typeof epgEntry === 'object' && epgEntry !== null) {
                epgEntry.startDate = timestampToDate(epgEntry.startTS);
                epgEntry.endDate = timestampToDate(epgEntry.startTS);
                epgEntry.available = this.convertStatus(epgEntry.available);
                epgEntry.channelId = channelId;
                epgEntry.channelName = channelName; // NOTE: possible code cleanup: remove this (should get this from somewhere else instead of passing to funct and appended to response)
            }
            return epgEntry;
        });
        return rv;
    }
}

export default APIEpg;
