import React, { FC } from 'react';
import styles from './Checkbox.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CheckboxPropTypes } from './Checkbox.types';

const Checkbox: FC<CheckboxPropTypes> = ({
    onClick,
    checked,
    text,
    classNames,
    withBorder,
    disabled,
    dataTestId
}) => {
    //const { t } = useTranslation();
    return (
        <span
            className={[styles.checkboxWrapper, classNames?.wrapper].join(' ')}
            data-testid={dataTestId}
            onClick={() => (onClick && !disabled ? onClick(!checked) : undefined)}
        >
            <div className={[styles.checkbox, classNames?.squareWrapper].join(' ')}>
                <div
                    className={[
                        styles.square,
                        withBorder ? styles.border : undefined,
                        classNames?.square
                    ].join(' ')}
                >
                    {checked && (
                        <FontAwesomeIcon icon={faCheck} className={styles.check} />
                    )}
                </div>
                {text && <span className={styles.text}>{text}</span>}
            </div>
        </span>
    );
};

export default Checkbox;
