import React, { useEffect, useState } from 'react';
import styles from './EditViewContainer.module.scss';
import DeleteRow from 'src/Widgets/common/basicElements/DeleteRow/DeleteRow';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
    createStatus,
    deleteStatus,
    editViewToggle,
    popupMessage,
    saveStatus,
   // selectGroup,
    showAllChannels,
    unSelectGroup
} from 'src/redux/actions/actions.channelGroupsWidget';
import {
    addChannels,
    addChannelsChunked,
    addGroup,
    deleteGroup,
    getGroups,
    updateGroup
} from 'src/redux/actions/actions.channelGroups';
import FormFooter from 'src/Widgets/common/basicElements/FormFooter/FormFooter';
//import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
import DuplicateRow from '../../shared/DuplicateRow/DuplicateRow';
import AdminRow from '../../components/AdminRow/AdminRow';

const EditViewContainer = ({isAdminUser}) => {
    const [editedTitle, setEditedTitle] = useState('');
    const [isAdminGroup, setIsAdminGroup] = useState(false);

    const channelGroups = useSelector((state) => state.channelGroupReducers);
    const {
        setPopupMessage,
        saveStatusLog,
        createStatusLog,
        //selectedGroupGroups,
        selectedGroupChannels,
        selectedGroupTitle,
        selectedGroupId,
        setDuplicateName,
        filteredChannels
    } = useSelector((state) => state.channelGroupsWidgetReducer);
    const profileGroups = useSelector(state => state.profileGroups?.data)

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const closeEditViewHandler = () => {
        const closeEditViewHandlerTimeout = setTimeout(() => {
            dispatch(editViewToggle());
        }, 100);
        return closeEditViewHandlerTimeout;
    };

    //Control closing on success
    useEffect(() => {
        (createStatusLog?.isSuccess || saveStatusLog?.isSuccess) && setTimeout(() => {
            closeEditViewHandler()
        }, 500);
    },[createStatusLog, saveStatusLog])

    const deleteGroupHandler = () => {
        dispatch(deleteGroup(selectedGroupId, [90105]))
        .then((res) => {
            const usedProfiles = res.usedByProfiles?.map((p) => p)
            const usedProfileTitles = profileGroups?.flatMap((group) => {
                return group?.profiles?.filter((profile) => {
                    return usedProfiles?.includes(profile.id)
                }).map((profile) => profile.title)
            })

            const uniqueUsedProfileTitles = [...new Set(usedProfileTitles)];

            if (res?.rc === 0) {
              dispatch(deleteStatus(0))
              dispatch(unSelectGroup());
              dispatch(getGroups())
            } else {
              dispatch(deleteStatus(res?.rc));
              const displayProfileTitles = uniqueUsedProfileTitles.slice(0, 5).map(title => "- " + title);
              if (uniqueUsedProfileTitles.length > 5) displayProfileTitles.push("- ......");
              dispatch(
                  popupMessage(`${t("Still used by profiles")} 
                  \n ${displayProfileTitles.join("\n")} 
                  \n Total number of profiles using this Channel Group: ${uniqueUsedProfileTitles?.length}`)
              );
            }
          });

        dispatch(showAllChannels(true))
    };

    const specialChannelIds = filteredChannels?.filter(
        (channel) => channel.isUnique || channel.isNotAvailable
      ).map(channel => channel.Id);

     const hasSpecialChannels = filteredChannels?.some(
        (channel) => channel.isUnique || channel.isNotAvailable
      ); 

      const uniqueChannels = selectedGroupChannels?.filter(
    (channel) => !specialChannelIds?.includes(channel?.id)
    );
    
    const specialChannels = selectedGroupChannels?.filter((channel) => specialChannelIds?.includes(channel?.id))  
    const notAvailableChannelNames = specialChannels?.filter(channel => channel?.id < 0 && channel?.title).map(channel => channel.title);
    const noAccessChannelNames = specialChannels?.filter((channel) => channel?.id > 0 && channel?.title).map((channel) => channel.title);    
    
    const popupConditional = () => {
        const noAccessChannels = noAccessChannelNames?.map((c) => noAccessChannelNames?.length > 0 && ' ' + c)
        const notAvailableChannels = notAvailableChannelNames?.map((c) => notAvailableChannelNames?.length > 0 && ' ' + c)
        
        if(noAccessChannelNames?.length && notAvailableChannelNames?.length){
            return `${t('Not duplicated some channels')} 
            ${t('Duplicate no access')}: ${noAccessChannels}
            ${t('Duplicate not available')}: ${notAvailableChannels}
            `
        }else if(!noAccessChannelNames?.length && notAvailableChannelNames?.length){
            return `${t('Not duplicated some channels')}
            ${t('Duplicate not available')}: ${notAvailableChannels}
            `
        }else if(noAccessChannelNames?.length && !notAvailableChannelNames?.length){
            return `${t('Not duplicated some channels')}
            ${t('Duplicate no access')}: ${noAccessChannels}
            `
        }
      }

    const duplicateChannelsHandler = async () => {
        channelGroups?.some((channel) => channel?.title !== setDuplicateName) &&
        await dispatch(addGroup({ title: setDuplicateName.trimEnd() }, [90102]))
          .then((res) => {
            if (res.rc === 0) {
              hasSpecialChannels && dispatch(popupMessage(popupConditional()))
              dispatch(createStatus(0));
              dispatch(addChannelsChunked(res.data.id, uniqueChannels));
             
              dispatch(getGroups())
            } else {
              dispatch(createStatus(res.rc));
            }
          });

          dispatch(showAllChannels(true))
      }  

    const inputChangeHandler = (e) => {
        let inputTitleValue = e.target.value;
             inputTitleValue = inputTitleValue.replace(/^\s/g, '')
        setEditedTitle(inputTitleValue);
    };

    const saveButtonHandler = () => {
        const res = dispatch(
            updateGroup(selectedGroupId, { title: editedTitle.trimEnd()}, [90121])
        );
        res.then((res) =>
            res?.rc === 0 ? dispatch(saveStatus(0)) : dispatch(saveStatus(res?.rc))
        );
    };

    useEffect(() => {
        (saveStatusLog?.isError || createStatusLog?.isError) &&
            dispatch(
                popupMessage(
                    `${t('cg_could_not_create')} ${
                        setDuplicateName || editedTitle
                    }. ${t('cg_title_exists')}.`
                )
            );
    }, [saveStatusLog, createStatusLog]);

    // const clearPopupLogic = () => {
    //     dispatch(popupMessage(''));
    // };

    // const popupShowConditionalHandler = setPopupMessage && !hasSpecialChannels && (
    //     <Popup wrapperStyle={{position:'fixed', insetBlockStart: 'unset', zIndex: '9999', insetBlockEnd: 0, height:'calc(100% - 2.2rem)'}} size="auto" blockContent={true} onOk={clearPopupLogic}>
    //         <div style={{ overflow: 'auto' }}>
    //             <p>{setPopupMessage}</p>
    //         </div>
    //     </Popup>
    // );

    useEffect(() => {
        setEditedTitle(selectedGroupTitle);
    }, [selectedGroupTitle]);

    return (
        <>
            {/* {popupShowConditionalHandler} */}
            <div className={styles.editViewContainer} data-testid="edit-view-container">
                <FormFooter
                    onGoBack={() => dispatch(editViewToggle())}
                    onAction={saveButtonHandler}
                    //Save button disabled if its same or empty
                    actionDisabled={!editedTitle || (editedTitle === selectedGroupTitle && (!editedTitle || !isAdminGroup))}
                    actionLabel={t('Save Changes')}
                    success={saveStatusLog?.isSuccess ? t('Successfully created') : null}
                    fail={saveStatusLog?.isError ? t('Could not save') : null}
                />
                <div className={styles.middleSection}>
                    {isAdminUser && (
                        <AdminRow
                            setIsAdminGroup={setIsAdminGroup}
                            isAdminGroup={isAdminGroup}
                        />
                    )}
                    <h3>{t('Title')}</h3>
                    <TextInput
                        value={editedTitle}
                        data-testid='cg-editTitle'
                        onChange={inputChangeHandler}
                        label={t('Title')}
                        placeholder={t('Enter Title')}
                        isRequired
                        className={styles.groupEditInput}
                    />
                    <DeleteRow
                        labelText={t('Remove Group')}
                        onClick={deleteGroupHandler}
                        confirmationText={t('Really delete group?')}
                        deletePopup={
                            setPopupMessage ? false : true
                        }
                    />

                    <DuplicateRow
                        labelText={t('Duplicate Channel Group')}
                        duplicateFunction={() => duplicateChannelsHandler()}
                        externalClass={styles.duplicateInput}
                    />
                </div>
            </div>
        </>
    );
};

export default EditViewContainer;
