import APITelemetry from 'src/API/APITelemetry';
import { SET, CLEAR } from '../types/actionTypes.error';
import { convertUrlToGeneric, endpoints } from 'src/API/endpoints';
import { getResponseHeader } from 'src/Widgets/common/helpers';
import { sendNetworkLvlTelemetry } from 'src/Widgets/common/helpers-tsx';

export const setError = ({ data, reportTelemetry }) => {
  return async (dispatch) => {
    let telemetryVarnishId;
    if (!reportTelemetry !== false) {
      const url = convertUrlToGeneric(data.url, endpoints);
      
      telemetryVarnishId = await sendNetworkLvlTelemetry(data, url, true);
    }

    dispatch({ type: SET
      , data: {...data, telemetryVarnishId}
     });
  }
}

export const clearError = data =>
  ({ type: CLEAR
  })