import React, { Component } from 'react';
import styles from './TabBar.module.scss';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
// import SelectAsync from '../SelectAsync/SelectAsync';

/**
 * Simple TabBar component
 *
 * props:
 *  - tabs: string[], list of names to display
 *  - active: integer, currently active index
 *  - onClick(id, label): func, returns index and label of clicked tab
 *  - customStyle: for alternative (in TabBar.module.css defined) custom style
 *  - maxTabs: maximum amount of visible tabs (if the number of provided tabs is exceeding this number, all other tabs get hidden - accessible via a 'dropdown' on the right hand side)
 *  - allowActiveClick: bool: decides if: execute onClick funct even if the clicked tab is the active tab
 */
class TabBar extends Component {
    constructor(props){
        super(props);

        this.state = {
            tabs: props.maxTabs ? props.tabs.slice(0,props.maxTabs) : props.tabs,
            showAll: false,
            height: props.height || '32px',
        }
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.tabs, prevProps.tabs)){
            this.setState({tabs: this.props.maxTabs ? this.props.tabs.slice(0, this.props.maxTabs) : this.props.tabs});
        }
    }

    renderTab(tab, idx){
        const props = this.props;
        return (
            <button
                key={idx}
                data-testid={`superLanguageTab-${typeof tab === 'object' ? tab.props.children[0] : tab}`}
                onClick={ (idx !== props.active || props.allowActiveClick) ? () => props.onClick(idx) : undefined}
                className={classNames(styles.button, {
                    [props.theme.backgroundPrimary]: idx === props.active && !props.colors,
                    [styles.lightText]: idx === props.active
                })}

                style={{
                    backgroundColor: props.colors && (idx === props.active) ? props.colors[idx] : null,
                    height: props.height,
                    // ...props.btnStyle
                }}
            >
                {tab}
            </button>
        )
    }

    render() {
        const props = this.props;
        const { tabs, showAll, height } = this.state;

        return (
            <div
                className={classNames(styles.tabBar, {
                    [styles[props.customStyle]]: props.customStyle
                })}
            >

                {/* render visible tabs */}
                <div className={styles.tabsRow}>
                    { props.maxTabs !== undefined && props.active >= props.maxTabs 
                        ?
                            <>
                                {_.map(tabs, (tab, idx) => (
                                    idx !== props.active && idx !== (tabs.length-1) && this.renderTab(tab, idx)
                                ))}
                                {this.renderTab(props.tabs[props.active], props.active)}    {/* render active tab */}
                            </>
                        :
                            _.map(tabs, (tab, idx) => (
                                this.renderTab(tab, idx)
                            ))
                    }
                    
                </div>
                {
                    props.tabs.length > tabs.length &&
                        // render 'closed dropdown'
                        <button
                            title={showAll ? props.t('Show less') : props.t('Show more')}
                            className={[styles.button].join(' ')}
                            style={{height: props.height}}
                            // style={{...props.btnStyle}}
                            onClick={()=>{this.setState({showAll: !showAll})}}
                        >
                            ... <FontAwesomeIcon icon={showAll ? "chevron-up" : "chevron-down"} />
                        </button>
                } 
                {
                    showAll &&
                    // render 'opened dropdown'
                        <>
                            <div
                                className={styles.dropDown}
                                style={{
                                    top: height,
                                }}
                            >
                            {props.tabs.map((tab, idx) => {
                                // render items as 'dropdown items', if they are not rendered as 'tab' already
                                if (!_.includes(tabs, tab) && tab !== props.tabs[props.active]) {
                                        return (<button
                                            className={[styles.button].join(' ')}
                                            onClick={() => {
                                                props.onClick(idx)
                                            }}
                                        >
                                            {tab}
                                        </button>)
                                    } else {
                                        return undefined;
                                    }
                                })}
                            </div>
                            <div className={styles.dropDownOverlay} onClick={()=>{this.setState({showAll: false})}}></div>
                        </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(TabBar));