import { useState } from 'react';
import { addChannelsChunked, addGroup, removeChannelsChunked } from 'src/redux/actions/actions.channelGroups';
import { useAppDispatch } from 'src/redux/hooks';
import { filterIdBySelectedTitle, lowerCaseSelectedChannels } from '../utils/utils';
import { ChannelGroup, ChannelType, Response, SelectBoxGroupOption, UseManageChannelGroupProps } from '../types/types';

const useManageChannelGroup = ({setSelectedGroupOptions, selectedGroupOptions, channelGroups, newGroupName, selectedChannels, selectedGroup, setPopupMessage}: UseManageChannelGroupProps) => {
    
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [popupType, setPopupType] = useState('');

    //Clear Popup Logic
    const clearPopupLogic = () => {
        setIsOpen(false);
        setPopupType('');
    }

    //Add channels into group Logic
    const handleAddChannelsToGroup = (groupId: number) => {
        const existingChannels = channelGroups?.find((group: ChannelGroup) => group?.id === groupId)?.channels || [];
        const newChannels = lowerCaseSelectedChannels(selectedChannels).filter((newChannel: ChannelType) => 
            !existingChannels.some((existingChannel: ChannelType) => existingChannel?.id === newChannel?.id)
        );
    
        if (newChannels.length === 0) {
            setPopupType('error');
            setPopupMessage('Channel With Same Name');
            setIsOpen(true);
            return;
        }
    
        if(selectedChannels){
            dispatch(addChannelsChunked(groupId, newChannels))?.then((res: any) => {
                if (res.every((r:any)=>r.rc === 0)) {
                    setPopupType('error');
                    setPopupMessage('Added Channels')
                    setIsOpen(true);
                    setSelectedGroupOptions([]);
                } else {
                    console.error('error');
                }
            });
        }
    };

    const handleGroupAddition = async (groupTitle: string) => {
        const res: Response = await dispatch(addGroup({ title: groupTitle }, [90102]));
        if (res.rc === 0) {
            handleAddChannelsToGroup(res.data.id);
        } else {
            console.error('error');
        }
        };

    //Add channels Logic
    const addChannelsHandler = () => {

            if (newGroupName.length > 0) {
                handleGroupAddition(newGroupName);
            }

            selectedGroupOptions.forEach((groupOption: SelectBoxGroupOption) => {
                const groupId = filterIdBySelectedTitle(groupOption, channelGroups);
                if (groupId) {
                    handleAddChannelsToGroup(groupId);
                }
            });
        
            if (newGroupName.length === 0 && selectedGroupOptions.length === 0) {
                console.error('There was a problem happened while adding channels');
            }
    };

    //Delete channels Logic
    const deleteChannelsHandler = () => {
        dispatch(removeChannelsChunked(selectedGroup?.id, selectedChannels)).then((res: any) => {
            if (res.every((r:any)=>r.rc === 0)) {
                clearPopupLogic();
            }else{
                console.log('error')
            }
        })
    }

  return {addChannelsHandler, deleteChannelsHandler, handleAddChannelsToGroup, clearPopupLogic, popupType, isOpen, setIsOpen, setPopupType}
}

export default useManageChannelGroup