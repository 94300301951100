import React, { useMemo, useRef, useState } from 'react';
import styles from './ClusterHeader.module.scss';
import useDragHorizontal from '../../hooks/useDragHorizontal';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCopy, faRss, faUndo } from '@fortawesome/free-solid-svg-icons';
import { dateToString, downloadFile, toLowerCase } from 'src/Widgets/common/helpers';
import APIReporting from 'src/API/APIReporting';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import { useHistory } from 'react-router-dom';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import useFindChannelType from '../../hooks/useChannelTypeFind';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';

const ClusterHeader = ({
    profileGroupContent,
    setActiveFilter,
    activeFilter,
    handleProfileFilter,
    selectedDateRange,
    isSearch,
    isEdited,
    isEditorial,
    tooltipVisible,
    setTooltipVisible,
    setBackFillSelectionVisible,
    backFillSelectionVisible,
    selectedChannelGroup,
    filteredDataToDisplay,
    tryQuery
}: MentionsVisualizerTypes.ClusterHeaderProps) => {
    const profileTitlesRef = useRef<HTMLDivElement>(null);
    const profileTitleSet = new Set();

    const history = useHistory();
    const {findChannelType} = useFindChannelType(profileGroupContent.clips);

    const { handleMouseDown } = useDragHorizontal({ itemsRef: profileTitlesRef });
    const { t } = useTranslation();

    const [downloadInProgress, setDownloadInProgress] = useState({
        xlsx: false,
        rss: false
    });

    //toggle folding of duplicates
    const toggleFolding = (isChecked: boolean) => {
        setActiveFilter((prev: any) => ({ ...prev, foldEnabled: isChecked }));
    };

    //handle RSS button click
    const handleRSSButtonClick = () => {
        setDownloadInProgress((prevState) => ({ ...prevState, rss: true }));
        const uniqueProfileIds = [
            ...new Set(
                profileGroupContent.clips.map(
                    (clip: { profileId: any; profileID: any }) =>
                        clip.profileId || clip.profileID
                )
            )
        ].join(',');

        history.push(
            `/embed/rssFeed/${uniqueProfileIds}/${dateToString(
                selectedDateRange.startDate
            )}/${dateToString(selectedDateRange.endDate)}`
        );
        setDownloadInProgress((prevState) => ({ ...prevState, rss: false }));
    };

    //find unique profile titles
    const uniqueProfileTitles = useMemo(() => {
        return profileGroupContent.clips
            .filter((clip: any) => {
                const isNewProfileTitle = !profileTitleSet.has(clip.profileTitle);
                profileTitleSet.add(clip.profileTitle);
                return isNewProfileTitle;
            })
            .map((clip: any) => {
                return {
                    ...clip
                };
            });
    }, [
        profileGroupContent.clips,
        activeFilter.channels,
        activeFilter.countries,
        activeFilter.type,
        isEditorial
    ]);
    
    //folded clips count by profile
    const foldedClipsCountByProfile = useMemo(() => {
        const filteredClips = profileGroupContent.clips.filter((clip: any) => {
            const matchesCountry = !activeFilter.countries.length || activeFilter.countries.includes(clip.cc);
            const matchesChannel = !activeFilter.channels.length || activeFilter.channels.includes(clip.channelName);
            const matchesType = !activeFilter.type || findChannelType(clip.channelNumber) === activeFilter.type;
            return matchesCountry && matchesChannel && matchesType;
        });
    
        if (activeFilter.foldEnabled) {
            return filteredClips
                .filter((clip: any) => clip.dupType === null)
                .reduce((acc: any, clip: any) => {
                    const profileId = isEditorial ? clip.profileID : clip.profileId;
                    acc[profileId] = (acc[profileId] || 0) + 1;
                    return acc;
                }, {});
        } else {
            return filteredClips.reduce((acc: any, clip: any) => {
                const profileId = isEditorial ? clip.profileID : clip.profileId;
                acc[profileId] = (acc[profileId] || 0) + 1;
                return acc;
            }, {});
        }
    }, [activeFilter.foldEnabled, activeFilter.countries, activeFilter.channels, activeFilter.type, profileGroupContent.clips, filteredDataToDisplay]);
    
    const handleChartIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setTooltipVisible(!tooltipVisible);
    };

    return (
        <>
            <h2 className={styles.profileGroupTitle}>
                {profileGroupContent.title === 'All Groups View'
                    ? t('All Groups View')
                    : (profileGroupContent.sharedBy ? '[SH] ' + profileGroupContent.title : profileGroupContent.title)}
            </h2>{' '}
            <h4
                style={{
                    margin: '5px'
                }}
            >
                {selectedChannelGroup
                    ? `${t('Channel Group')}: ${selectedChannelGroup}`
                    : null}
            </h4>
            {uniqueProfileTitles.length > 0 && (
                <div className={styles.profileTitlesContainer}>
                    <div
                        className={styles.profileTitles}
                        ref={profileTitlesRef}
                        onMouseDown={handleMouseDown}
                        style={
                            uniqueProfileTitles.length > 1
                                ? {
                                      cursor: 'grab',
                                      borderRight: '2px solid rgb(0, 56, 85)'
                                  }
                                : { cursor: 'default' }
                        }
                    >
                        {uniqueProfileTitles
                            .sort((a, b) => {
                                const countA =
                                    foldedClipsCountByProfile[
                                        isEditorial ? a.profileID : a.profileId
                                    ] || 0;
                                const countB =
                                    foldedClipsCountByProfile[
                                        isEditorial ? b.profileID : b.profileId
                                    ] || 0;
                                return countB - countA;
                            })
                            .map(
                                (clip: {
                                    profileId: any;
                                    notifID: React.Key | null | undefined;
                                    profileTitle: string;
                                    profileID?: string | number;
                                    filteredCount: any;
                                }) => {
                                    const isActive =
                                        (isEditorial
                                            ? clip.profileID
                                            : clip.profileId) === activeFilter.profile;

                                    return (
                                        <Button
                                            key={clip.notifID}
                                            type={isActive ? 'secondary' : 'primary'}
                                            data-testid={clip.profileTitle}
                                            btnClass={styles.profileTitleButton}
                                            disabled={
                                                //check if foldedClipsCountByProfile object has profile id as key
                                                !foldedClipsCountByProfile[
                                                    isEditorial
                                                        ? clip.profileID
                                                        : clip.profileId
                                                ]
                                            }
                                            onClick={() =>
                                                handleProfileFilter(
                                                    isEditorial
                                                        ? clip.profileID
                                                        : clip.profileId
                                                )
                                            }
                                        >
                                            <span
                                                title={`${clip.profileTitle} (${
                                                    foldedClipsCountByProfile[
                                                        isEditorial
                                                            ? clip.profileID
                                                            : clip.profileId
                                                    ]
                                                })`}
                                                className={styles.profileTitleCount}
                                            >
                                                {clip.profileTitle?.length > 20
                                                    ? clip.profileTitle.substring(0, 20) +
                                                      '...'
                                                    : clip.profileTitle}{' '}
                                                (
                                                {foldedClipsCountByProfile[
                                                    isEditorial
                                                        ? clip.profileID
                                                        : clip.profileId
                                                ] || 0}
                                                )
                                            </span>
                                        </Button>
                                    );
                                }
                            )}
                    </div>
                    <div className={styles.downloadIcons}>
                        {!isSearch && (
                            // <div
                            //     title={
                            //         activeFilter.foldEnabled
                            //             ? t('Show Duplicates')
                            //             : t('Hide Duplicates')
                            //     }
                            //     style={
                            //         activeFilter.foldEnabled
                            //             ? { color: '#c4a01b' }
                            //             : { color: '#23527c' }
                            //     }
                            //     className={styles.foldDuplicatesIcon}
                            //     onClick={() => toggleFolding(!activeFilter.foldEnabled)}
                            // >
                            //     <span
                            //         style={{
                            //             fontSize: '1rem',
                            //             minWidth: '9rem',
                            //             display: 'flex',
                            //             gap: '0.2rem',
                            //             alignItems: 'center',
                            //             justifyContent: 'center'
                            //         }}
                            //     >
                            //         <FontAwesomeIcon icon={faCopy} />
                            //         {activeFilter.foldEnabled
                            //             ? t('Show Duplicates')
                            //             : t('Hide Duplicates')}
                            //     </span>
                            // </div>
                        
                            <Checkbox
                                withBorder
                                text={t('Fold Duplicates')}
                                checked={activeFilter.foldEnabled}
                                onClick={() => toggleFolding(!activeFilter.foldEnabled)}
                                classNames={
                                    { wrapper: styles.foldDuplicatesIcon, square: styles.foldDuplicatesIcon, squareWrapper: styles.foldDuplicatesIcon }
                                }
                            />
                        
                        )}
                        {'  | '}
                        {
                            <div
                                className={styles.chartIcon}
                                onClick={handleChartIconClick}
                                data-testid="chart-icon"
                            >
                                <FontAwesomeIcon icon={faChartLine} />
                            </div>
                        }
                        {isSearch && (
                            <div
                                className={styles.backFillIcon}
                                data-testid="backfill-icon"
                                onClick={() =>
                                    setBackFillSelectionVisible(!backFillSelectionVisible)
                                }
                            >
                                <FontAwesomeIcon icon={faUndo} />
                            </div>
                        )}
                        <div
                            className={styles.xlsxDownloadIcon}
                            data-testid="xlsx-download-icon"
                            onClick={async () => {
                                setDownloadInProgress((prevState) => ({
                                    ...prevState,
                                    xlsx: true
                                }));
                                const uniqueProfileIds = [
                                    ...new Set(
                                        profileGroupContent.clips.map(
                                            (clip: { profileId?: string | number, profileID?: string | number }) => {
                                                const id = clip.profileId || clip.profileID;
                                                let modifiedId = tryQuery
                                                    ? tryQuery.profileID + 't'
                                                    : isSearch
                                                        ? id + 't'
                                                        : id;
                                                if (isEdited) {
                                                    modifiedId += 'e';
                                                }
                                                return modifiedId;
                                            }
                                        )
                                    )
                                ];
                                
                                const xlsx = await APIReporting.getAllHitsXlsx(
                                    activeFilter.profile ? [activeFilter.profile] : uniqueProfileIds,
                                    {
                                        from: dateToString(selectedDateRange.startDate),
                                        to: dateToString(selectedDateRange.endDate)
                                    }
                                );
                                downloadFile(
                                    xlsx,
                                    `eMM_profile-hits_${profileGroupContent.title}.xlsx`,
                                    'xlsx'
                                );
                                downloadFile(
                                    xlsx,
                                    `eMM_profile-hits_${profileGroupContent.title}.xlsx`,
                                    'xlsx'
                                );
                                setDownloadInProgress((prevState) => ({
                                    ...prevState,
                                    xlsx: false
                                }));
                            }}
                        >
                            {downloadInProgress.xlsx ? (
                                <LoadingSpinner size="1.2rem" />
                            ) : (
                                <img src={'/mms/images/xlsx.png'} alt="xlsx" width={21} />
                            )}
                        </div>
                        {!isSearch && (
                            <div
                                onClick={handleRSSButtonClick}
                                data-testid="rss-download-icon"
                                className={styles.rssDownloadIcon}
                            >
                                {downloadInProgress.rss ? (
                                    <LoadingSpinner size="1.2rem" />
                                ) : (
                                    <FontAwesomeIcon icon={faRss} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ClusterHeader;
