import ReactDatePicker, { DatePickerProps as ReactDatePickerProps } from 'react-date-picker';

interface DatePickerProps extends ReactDatePickerProps {
    onChange?: (value: Date | null) => void;
}

function DatePicker(props: Readonly<DatePickerProps>) {
    const onchange = (date: Date) => {
        if (props.onChange) {
            // if we dont have a valid date (e.g. user removed part of it, we return null)
            const value = date && date instanceof Date ? date : null;
            props.onChange(value);
        }
    }

  return (
      <ReactDatePicker
          {...props}
          onChange={onchange}
      />
  )
}

export default DatePicker