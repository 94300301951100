import GenericCalls from './genericCalls';
import _ from 'lodash';

class APIProfiles {
    static convertToBackend(profile) { // NOTE: possible code cleanup
        let rv = _.cloneDeep(profile);
        rv.queries = Object.keys(rv.queries).reduce(
            (sum, langKey) => {
                let sQuery = rv.queries[langKey].standardQuery;
                if (sQuery) {
                    sQuery.type = 'simple';
                    sQuery.lang = langKey;
                    sQuery.uiPosition = 0;
                }
                rv.queries[langKey].superQuery = rv.queries[langKey].superQuery || [];
                rv.queries[langKey].superQuery = rv.queries[langKey].superQuery.map(o => (
                    // (o.query) || o.delete ? { ...o, lang: langKey } : undefined
                    { ...o, lang: langKey }
                ));
                rv.queries[langKey].superQuery = rv.queries[langKey].superQuery.filter(o => o !== undefined);
                return sum.concat(rv.queries[langKey].superQuery.concat(
                    rv.queries[langKey].standardQuery !== undefined
                    && (rv.queries[langKey].standardQuery.query || rv.queries[langKey].standardQuery.delete)
                        ? [rv.queries[langKey].standardQuery]
                        : []
                ))
            },[]
        );
        rv = {
            id: rv.id,
            title: rv.title,
            isSuper: Number(rv.isSuper),
            isEnabled: Number(rv.isEnabled),
            notifyIM: Number(rv.notifyIM),
            notifyEmail: rv.notifyEmail,
            autoSumLang: rv.autoSumLang,
            profileGroupId: rv.profileGroupId,
            channelGroupId: rv.channelGroupId,
            queries: rv.queries.map(o => {
                let rv;
                let base = {
                    qid: o.id,
                    delete: Number(o.delete),
                    enabled: Number(!o.disabled),
                    client_id: o.client_id,
                }
                if(o.delete !== true) {
                    rv = {
                        query: o.query,
                        title: o.title,
                        uiPosition: o.uiPosition,
                        lang: o.lang,
                        type: o.type,
                    }
                }
                return {...base, ...rv};
        }),
        }

        return rv;
    }
    static convertFromBackend(profile) {  // NOTE: possible code cleanup
        let rv = _.cloneDeep(profile);
        rv.data.isSuper = rv.data.isSuper === 1 ? true : false;
        rv.data.isEnabled = rv.data.isEnabled === 1 ? true : false;
        rv.data.notifyIM = rv.data.notifyIM === 1 ? true : false;
        let languages = {};

        rv.data.queries.forEach((e) => {
            e.disabled = e.enabled === 1 || e.enabled === null ? false : true;
            delete e.enabled;
            e.id = e.qid;
            e.title = e.qtitle;
            delete e.qtitle;
            e.spamStatus = e.spamCheckTime < e.mtime ? -1 : e.isSpam;
            delete e.isSpam;
            delete e.qid;
            if (!languages[e.lang]) {
                languages[e.lang] = {standardQuery: {}, superQuery: []};
            }
            if (['alert','topic','discard'].includes(e.type)) {
                languages[e.lang].superQuery.push(e);
            } else if (e.type === 'simple') {
                languages[e.lang].standardQuery = e;
            } else {
                console.log('unknown query type');
            }
        });

        rv.data.queries = languages;


        //ensure we use unique uiPositions for queries within the same language (which was not the case due to initial implementation always using uiPosition=0)
        Object.keys(rv.data.queries).map(lang => {
            // sort by uiPosition
            rv.data.queries[lang].superQuery = rv.data.queries[lang].superQuery.sort((a,b)=>a.uiPosition - b.uiPosition);
            return undefined;
        });
        // check if uiPosition is equal for two consecutive items within one language
        Object.keys(rv.data.queries).map(lang => {
            let hasDupPosition = false;
            rv.data.queries[lang].superQuery.map((q, index) => {
                let prev;
                if(index > 0){
                    prev = rv.data.queries[lang].superQuery[index-1];
                    if(prev.uiPosition >= q.uiPosition){
                        hasDupPosition = true;
                    }
                }
                return q;
            });
            if(hasDupPosition){
                // uiPosition has dup items within language - resetting all of them to index (= giving them unique numbers)
                rv.data.queries[lang].superQuery = rv.data.queries[lang].superQuery.map((q, index) => { q.uiPosition = index; return q; });
            }
            return undefined;
        })
        return rv;
    }

    static async get(id) {
        if (id) {
        //     return this.convertFromBackend(
        //      {rc:0,data:{
        //         "id": "_development_",
        //             "title": "string",
        //                 "isSuper": false,
        //                     "queries": [
        //                         {
        //                             "id": 42,
        //                             "query": "+bmw+audi",
        //                             "title": "string",
        //                             "uiPosition": 2,
        //                             "lang": "de",
        //                             "type": "alert",
        //                             // "delete": true
        //                         },
        //                         {
        //                             "id": 43,
        //                             "query": "cars",
        //                             "title": "string",
        //                             "uiPosition": 2,
        //                             "lang": "de",
        //                             "type": "topic",
        //                             // "delete": true
        //                         },
        //                         {
        //                             "id": 44,
        //                             "query": "+toyota+ferrari",
        //                             "title": "string",
        //                             "uiPosition": 2,
        //                             "lang": "en",
        //                             "type": "alert",
        //                             // "delete": true
        //                         },
        //                         {
        //                             "id": 45,
        //                             "query": "+some+query",
        //                             "title": "string",
        //                             "uiPosition": 2,
        //                             "lang": "en",
        //                             "type": "simple",
        //                             // "delete": true
        //                         },
        //                         {
        //                             "id": 46,
        //                             "query": "+other+query",
        //                             "title": "string",
        //                             "uiPosition": 2,
        //                             "lang": "xx",
        //                             "type": "simple",
        //                             // "delete": true
        //                         },
        //                     ],
        //                         "isEnabled": true,
        //                             "notifyIM": true,
        //                                 "notifyEmail": 0,
        //                                     "profileGroupId": 127241,
        //                                         "channelGroupId": null
        //     }
        // })
            
        return this.convertFromBackend(
                await GenericCalls.get('/api/profiles/' + id)
            );
        }
        else return await GenericCalls.get('/api/profiles');
    }

    static async spamCheck(profileId, queryId, hideErrors) {
        return await GenericCalls.get(
            `/api/profiles/${profileId}/${queryId}/spamCheck`,
            undefined,
            undefined,
            hideErrors
        );
    }

    static async getXls() { // all profiles of requesting user, 'exported' as xls
        return await GenericCalls.get('/api/profiles/all/xlsx', undefined, { responseType: 'blob' });
    }

    static mapReceivedQueryIds(clientData, server_qids) {
        clientData = _.cloneDeep(clientData);
        // map database ids (qid) to the temporary ids which where assigned in the client (client_id)
        let rv = [];
        for (let i = 0; i < server_qids.length; i++){
            const qid = server_qids[i];
            const noIdIndex = _.findIndex(clientData.queries, (o)=> o.qid === undefined || o.qid === null);
            if (noIdIndex !== -1) {
                rv.push({
                    client_id: clientData.queries[noIdIndex].client_id,
                    qid,
                });
                clientData.queries[noIdIndex].qid = qid;
            }
        }
        return rv;
    }
    
    static async post(data) {
        const toBackend = this.convertToBackend(data);
        let res = await GenericCalls.post(
            '/api/profiles/',
            toBackend,
            // { contentType: 'application/json; charset=utf-8' }
        );
        if (res.data && res.data.qids) {
            res.queryIds = APIProfiles.mapReceivedQueryIds(toBackend, res.data.qids);
        }
        return res;
    }
    
    static async put(id, data, hideErrors) {
        const toBackend = this.convertToBackend(data);
        let res = await GenericCalls.put(
            '/api/profiles/' + id,
            toBackend,
            undefined,
            hideErrors
            // { contentType: 'application/json; charset=utf-8' }
        );
        if (res.data && res.data.qids) {
            res.queryIds = APIProfiles.mapReceivedQueryIds(toBackend, res.data.qids);
        }
        return res;
    }
    
    static async delete(id, hideErrors) {
        return await GenericCalls.delete('/api/profiles/' + id, hideErrors);
    }

    static async getShareGroups(id) {
        return await GenericCalls.get(`/api/profiles/${id}/share-groups`);
    }

    static async addShareGroups(id, shareGroupIds){
        return await GenericCalls.post(`/api/profiles/${id}/share-groups/${shareGroupIds}`);
    }

    static async removeShareGroups(id, shareGroupIds){
        return await GenericCalls.delete(`/api/profiles/${id}/share-groups/${shareGroupIds}`);
    }
}

export default APIProfiles;
