import React from 'react';
import styles from './Slider.module.scss';
import classNames from 'classnames';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
library.add(faChevronRight, faChevronLeft);

/*
props:
 - onSliderChange: called when value of slider gets modified
 - value: current value of slider
 - min/max: possible values (min max)
 - minLabel/maxLabel: label at begin and end of slider
 - valueLabel:[true|false] default=true: allows to hide/display the value label of a slider
 - showArrows: [true|false] default=true
*/

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  };
        
        this.onSliderChange = this.onSliderChange.bind(this);
        this.onDecreaseClick = this.onDecreaseClick.bind(this);
        this.onIncreaseClick = this.onIncreaseClick.bind(this);
    }

    onSliderChange(value) {
        if(this.props.disabled) {
            return;
        }
        this.props.onSliderChange(parseInt(value));
    }

    onDecreaseClick() {
        if(this.props.disabled) {
            return;
        }
        if ((this.props.value > this.props.min && this.props.min !== undefined) || (this.props.value > 0 && this.props.min === undefined)) {
            this.onSliderChange(parseInt(this.props.value) - 1);   
        }        
    }

    onIncreaseClick() {
        if(this.props.disabled) {
            return;
        }
        if ((this.props.value < this.props.max && this.props.max !== undefined) || (this.props.value < 100 && this.props.min === undefined)) {
            this.onSliderChange(parseInt(this.props.value) + 1);   
        }        
    }
    
    render() {
        // TODO: get slider position rerendered after changing value by using arrows
        const min = this.props.min ? this.props.min : undefined;
        const max = this.props.max ? this.props.max : undefined;        
        const val = this.props.value;
        const valueLabel = this.props.valueLabel !== undefined ? this.props.valueLabel : true;       
        const showArrows = this.props.showArrows;
        const dir = this.props.i18n.dir();

        return (
            <span>
                <div className={[styles.slidecontainer, this.props.disabled ? styles.disabled : ''].join(' ')}>
                    <span
                        className={classNames(styles.sideLabel, styles.left)}
                        onClick={this.onDecreaseClick}
                    >
                        {
                            showArrows ?
                                <FontAwesomeIcon className={styles.arrow} icon={dir === 'rtl' ? 'chevron-right' : 'chevron-left'} />
                            :null
                        }
                        <span className={styles.text}>{this.props.minLabel}</span>
                    </span>
                    <input
                        ref={this.props.inputRef}
                        type="range" min={min}
                        onChange={(e) => { this.onSliderChange(e.target.value) }}
                        max={max} value={val}
                        className={styles.slider} />
                    <span
                        className={classNames(styles.sideLabel, styles.right)}
                        onClick={this.onIncreaseClick}
                    >
                        <span className={styles.text}>{this.props.maxLabel}</span>
                        {showArrows ?
                            <FontAwesomeIcon className={styles.arrow} icon={dir === 'rtl' ? 'chevron-left' : 'chevron-right'} />
                        :null}
                    </span>
                </div>
                {valueLabel ?
                    <div className={styles.label}>{val}</div>
                : null}
            </span>
        );
    }
}

export default withTranslation()(Slider);