import React, { Component } from 'react';
import _ from 'lodash';
import styles from './QueryInput.module.scss';
import AutoComplete from '../../basicElements/TextInput/AutoComplete';
import { completeWord, sanitizeQuery, trackEvent } from '../../helpers';
import { withTranslation } from 'react-i18next';
import InfoBox from '../../basicElements/InfoBox/InfoBox';
import LabeledSwitch from '../../basicElements/SwitchElements/LabeledSwitch';
import LabeledSelect from '../../basicElements/LabeledSelect/LabeledSelect';
import QueryVisualization from '../../QueryVisualization/QueryVisualization';
import Button from '../../basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faInfoCircle, faEyeSlash, faPlus, faExclamationTriangle,
    // faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { browserName } from 'react-device-detect';
import { BROWSER_CHROME, BROWSER_FIREFOX } from 'src/consts';
import { connect } from 'react-redux';
import TextInput from '../../basicElements/TextInput/TextInput';
import Query from './Query';
import SpamStatus from './SpamStatus/SpamStatus';
import TabBar from '../../basicElements/TabBar/TabBar';
import IconCount from '../../basicElements/IconCount/IconCount';
import Sortable from '../../DragDrop/Sortable';
import Checkbox from '../../basicElements/Checkbox/Checkbox';
import Popup from '../../basicElements/Popup/Popup';
import SelectAsync from '../../basicElements/SelectAsync/SelectAsync';
import APITranslations from 'src/API/APITranslation/APITranslations';
import StyledTable from '../../basicElements/StyledTable/StyledTable';
import APIQueryVisualization from 'src/API/APIQueryVisualization';
import { sendRequests } from '../../helpers-tsx';
import LoadingIndicator from '../../basicElements/LoadingIndicator/LoadingIndicator';
// import { Draggable, Droppable } from 'react-beautiful-dnd';

/**
 * Query component to be used in profile details
 */
class QueryInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTree: {},
            activeLangStandard: undefined,  // active language for standard profiles (currently set when using translate button)
            translateSuperQuery: {}, // defined if text or input textfield should be shown (browser translation needs text - not working on input)
            languages: [],
            focusedQuery: {},
            selectAll: false,
            showTranslateModal: false,   // wheter to show popup for translating super queries or not
            translateTo: null, // [] of langs to which the user wants to translate
            showTranslateOverrideWarn: false,
            langWithQueries: [], // array of languages (out of selected langs for translation) which already have queries - list will be shown when translating
            translateDone: false,   // used to show a popup after translation is completed
            translateCallsTotal: 0,
            translateCallsDone: 0,
            translateFailed: [],
        };
        this.superQueryRefs = {};

        this.queryTypes = [
            {key: 'alert', title: this.props.t('Alert'), description: this.props.t('query_alert_description')},
            {key: 'topic', title: this.props.t('Topic Words'), description: this.props.t('query_topic_description')},
            {key: 'discard', title: this.props.t('Discard'), description: this.props.t('query_discard_description')},
        ];

        this.onAfterTreeFetch = this.onAfterTreeFetch.bind(this);
        this.setQueryInvalid = this.setQueryInvalid.bind(this);
        this.showQueryTree = this.showQueryTree.bind(this);
    }

    async showQueryTree(showVisualization, languageKey, index) {
        let showTree = {};
        Object.keys(this.props.profile.queries).forEach((key) => { showTree[key] = index !== undefined ? {} : false; });
        if (index !== undefined) {
            showTree[languageKey][index] = showVisualization;
        } else {
            showTree[languageKey] = showVisualization;
        }
        this.setState({ showTree });
    }

    componentDidMount() {
        this.sortLangs();
    }

    sortLangs() {
        const profile = this.props.profile;
        let languages = this.props.supportedLanguages.map(o => ({
            key: `${o}`,
            value: this.props.t(`${o}`),
            // queries: profile.isSuper
            //     ? (this.props.profile.queries[o].superQuery ? this.props.profile.queries[o].superQuery.length : 0)
            //     : undefined//(hasContent(this.props.profile.queries[o].query) ? 1 : 0)
        }));
        _.remove(languages, { key: '**' });
        const xxIdx = languages.findIndex(o => o.key === 'xx');
        if (xxIdx !== -1) { languages.splice(xxIdx, 1); }
        
        let browserLangs = _.uniq(window.navigator.languages.map(e => e.slice(0, 2))); // unique browser langs (using two letter codes only - removing region letters after dash)
        browserLangs = _.filter(browserLangs, (l) => {  // remove those browser languages which we do not show in UI anyways
            const isIncluded = languages.some((lang, index) => {
                if (lang.key === l) {
                    languages.splice(index, 1);
                }
                return lang.key === l;
            });
            return isIncluded;
        });
        browserLangs = browserLangs.map(l => ({key:l, value: this.props.t(l)}));

        languages = _.orderBy(languages, [
            // profile.isSuper ? function (item) { return item.queries; } : undefined,
            function (item) { return profile.isSuper ? item.key : item.value; }
        ], [
            // profile.isSuper ? "desc" : undefined,
            "asc"
        ]);

        languages = browserLangs.concat(languages); // show browser languages first (order as set by user), then alphabetically sorted other languages

        // languages = languages.sort((a, b) => 
        //      a[sortKey] < b[sortKey] ? -1 : (a[sortKey] > b[sortKey]) ? 1 : 0
        // );
        languages = languages.map(o => o.key);
        languages.push('**');
        if (xxIdx !== -1) { languages.unshift('xx'); }
        this.setState({languages})
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isSuper !== this.props.isSuper
            || prevProps.supportedLanguages !== this.props.supportedLanguages
        ) {
            this.sortLangs();
        }
        // if (prevProps.profile.queries['xx'] && !this.props.profile.queries['xx']) {
        //     console.log('updated')
        //     this.sortLangs();
        //     return true;
        // }
    }

    onTranslateBtnClick(languageKey, index, callback) {
        // TODO: fix this if we want to show this to the user again
        // let confirm = true;
        // if(
        //     document.getElementById('goog-gt-tt') !== null &&  // browsers translate functionality was used
        //     document.getElementsByTagName('html')[0].getAttribute('lang') !== languageKey
        //     ){
        //     confirm = window.confirm(`${this.props.t("It looks like you did an automated translation but used another language")}. ${this.props.t("Are you sure the provided information is correct")}`)
        //     // note: not sure if we should show this. it will also show up if a user clicks translate+done without having used browsers translate on the current query (but on another query)
        // }
        // return confirm;
        return true;
    }

    onAfterTreeFetch(res, lang, index, debounce) {
        let isInvalid = false;
        if (res) {
            switch (res.rc) {
                case 90013:
                    isInvalid = true;
                    break;
                default: break;
            }
        }
        const params = [isInvalid, lang, index];
        debounce
            ? this.debouncedSetQueryInvalid(...params)
            : this.setQueryInvalid(...params);
    }

    async setQueryInvalid(value, lang, index) {
        this.debouncedSetQueryInvalid.cancel();
        if(!this.props.profile.isSuper){
            index = 0;   
        }
        if (value) {
            let q = this.props.queries[lang];
            if (this.props.profile.isSuper) {
                q = q.superQuery[index].query;
            } else { q = q.standardQuery.query; }
            trackEvent(this.props.matomo, 'profile', 'edit query(invalid)',{q});
        }
        this.props.setQueryValid(!value, lang, index);
    }
    debouncedSetQueryInvalid = _.debounce(this.setQueryInvalid, 3000);

    getQueryInvalid(lang, index) {
        let queries = this.props.queries;
        const invalidText = this.props.t('The query syntax is invalid. Please check the query.')
        if (this.props.isSuper) {
            if (queries[lang]?.superQuery[index]?.isValid === false) {
                return invalidText;
            }
        } else {
            if (queries[lang]?.standardQuery?.isValid === false) {
                return invalidText;
            }
        }
        return;
    }

    onAutoCompleteStandardQuery(query, autocomplete, callback) {
        // callback expects event
        callback({ target: { value: completeWord(query, autocomplete) } });
    }

    renderQueryGroup(typeTitle, queryType, title, query, index, languageKey, showVis, isDisabled, queryId, spamCheck, spamStatus, syntaxInvalid, selected, dupTitle, queryEdited) { // TOOD: rename querygroup --> superQuery
        const { t } = this.props;
        showVis = this.state.showTree[languageKey] ? this.state.showTree[languageKey][index] : undefined; // added this to unify show/hide vis. behaviour (only show one query vis. at once) (but keeping 'showVis' param/state in case we want to change this behaviour again)

        const queryTypes = this.queryTypes.map((qt)=>({
            label: qt.title,
            value: qt.key,
        }))

        const queryTypeSelected = _.find(queryTypes, {
            value: queryType
        });

        return (
        // <Draggable id={`drag-${index}`}>
        // <Draggable key={uiPosition} draggableId={`drag-${uiPosition}`} index={uiPosition}>
            // {/* bug/issue with uiPosition used multiple times ?? */}
            // {(provided, snapshot) => (
            <div
                // ref={provided.innerRef}
                // {...provided.draggableProps}
                // {...provided.dragHandleProps}
                className={[styles.queryGroup, 
                    // this.props.theme.borderPrimary
                ].join(' ')}
            >
                <span className={[
                    isDisabled ? this.props.theme.backgroundPrimary_disabled :this.props.theme.backgroundPrimary, 
                                styles.superQueryLabel].join(' ')}
                > 
                    <Checkbox
                        disabled={this.props.profileSharedBy !== null}
                        onClick={() => { this.props.selectQuery(!selected, languageKey, index) }}
                        checked={selected}
                    />
                    <span className={[styles.queryType, LabeledSelect.styles].join(' ')}> 
                        <LabeledSelect
                            isDisabled={isDisabled || this.props.profileSharedBy !== null}
                            isRequired
                            options={queryTypes}
                            value={queryTypeSelected}
                            onChange={option => {
                                this.props.updateQueryGroupType(languageKey, index, option.value);
                            }}
                        />
                    </span>
                        <TextInput
                            disabled={isDisabled || this.props.profileSharedBy !== null}
                            data-testid={`queryTitle-${index}`}
                            className={styles.titleInput}
                            placeholder={t('Title')}
                            invalid={dupTitle}
                            value={title}
                            onFocus={() => { this.setState({isQueryTitleFocused: true}) }}
                            onBlur={()=>{ this.setState({isQueryTitleFocused: undefined}) }}
                            onChange={(e) => { this.props.updateQueryGroup(languageKey, index, { title: e.target.value, query, showVis }); }}
                    />
                    {!isDisabled && !queryEdited && <SpamStatus
                        spamStatus={spamStatus}
                        style={{marginInlineStart:'.4rem', marginInlineEnd:'0'}}
                        runSpamCheck={()=>{ this.props.runSpamCheck(this.props.queries[languageKey].superQuery[index], languageKey, index); }}
                    />}

                    <div className={styles.icons}>
                        {/* <div className={[styles.translateBtn].join(' ')}>
                            {
                                languageKey !== 'xx' &&
                                this.state.translateSuperQuery[languageKey] === index ?
                                    <Button
                                        onClick={() => {
                                            if (this.onTranslateBtnClick()) {
                                                this.props.updateQueryGroup(languageKey, index, { title, query: this.superQueryRefs[languageKey][index].textContent, showVis })
                                                let newState = _.cloneDeep(this.state);
                                                newState.translateSuperQuery = {};
                                                this.setState(newState);
                                            }
                                        }}
                                        btnClass={[
                                            styles.superTranslateBtn,
                                            this.props.theme.backgroundPrimary_reverse].join(' ')
                                        }
                                    >
                                        {this.props.t('done')}
                                    </Button>
                                    :
                                <Button
                                    btnClass={[
                                        styles.superTranslateBtn,
                                        this.props.theme.backgroundPrimary_reverse].join(' ')
                                    }
                                    disabled={browserName !== BROWSER_CHROME || !query || isDisabled}
                                    title={browserName !== BROWSER_CHROME ? this.props.t('translate_use_chrome') : this.props.t('query_translate_explanation')}
                                    onClick={() => {
                                        let newState = _.cloneDeep(this.state);
                                        newState.translateSuperQuery = {};
                                        newState.translateSuperQuery[languageKey] = index;
                                        this.setState(newState);
                                    }}
                                >
                                    {this.props.t('translate')}
                                </Button>
                            }
                        </div> */}
                        <Button
                            disabled={isDisabled|| !query || this.props.profileSharedBy !== null || syntaxInvalid}
                            btnClass={[this.props.theme.backgroundPrimary_reverse].join(' ')}
                            data-testid={`tryQueryButton-${index}`}
                            onClick={()=>{
                                //this.props.saveProfile();
                                this.props.tryQuery({
                                    query: this.props.profile.queries[languageKey].superQuery[index].query,
                                    lang: languageKey,
                                    qid: this.props.profile.queries[languageKey].superQuery[index].id,
                                    qindex: index,
                                })
                            }}
                        >
                            {t('profile_try_query')}
                        </Button>
                        <Button
                            btnClass={[
                                this.props.theme.backgroundPrimary_reverse].join(' ')
                            }
                            title={this.props.t('show_hide_query_vis')}
                            disabled={isDisabled || !query}
                            style={{ backgroundColor: languageKey }}
                            onClick={() => {
                                const val = this.state.showTree[languageKey] ? !this.state.showTree[languageKey][index] : true;
                                this.showQueryTree(val, languageKey, index);
                                // this.props.updateQueryGroup(languageKey, index, { title, query, showVis: !showVis })
                            }}
                        >
                            <FontAwesomeIcon
                                icon={showVis ? faEyeSlash : faEye}
                            />
                        </Button>
                        <LabeledSwitch
                            disabled={this.props.profileSharedBy !== null}
                            // className={[this.props.theme.backgroundPrimary_reverse].join(' ')}
                            checked={isDisabled !== undefined ? !isDisabled : true}
                            onChange={(val) => this.props.disableQuery(!val, languageKey, index)}
                            labelPosition='start'
                            className={[styles.switch].join(' ')}
                        >
                            {t('enable_query')}
                        </LabeledSwitch>
                        
                        <Button
                            btnClass={[
                                this.props.theme.backgroundPrimary_reverse].join(' ')
                            }
                            style={{ backgroundColor: languageKey }}
                            onClick={() => {
                                this.props.removeSuperQuery(languageKey, index);
                            }}
                            title={this.props.t('Delete query')}
                            disabled={this.props.profileSharedBy !== null}
                        >
                            <FontAwesomeIcon
                                icon={faTrash}
                            />
                        </Button>
                    </div>
                </span>

                {spamCheck && this.props.renderSpamFailed(spamCheck)}

                {
                    showVis &&
                    <QueryVisualization
                        // data={this.props.profile.queries[languageKey].superQuery[index].queryTree}
                        query={query}
                        queryId={queryId}
                        onAfterTreeFetch={(val) => {
                            this.onAfterTreeFetch(
                                val,
                                languageKey,
                                index,
                                this.state.focusedQuery.index === index
                                && this.state.focusedQuery.languageKey === languageKey
                                && this.state.focusedQuery.wasEmptyOnFocus
                            )
                        }}
                        languages={[languageKey]}
                        requesting={this.props.requesting}
                        onLmuStatusChange={this.props.onLmuStatusChange}
                    />
                }

                {this.state.translateSuperQuery[languageKey] === index
                    ? <div
                        ref={(r) => {
                            if (!this.superQueryRefs[languageKey]) {
                                this.superQueryRefs[languageKey] = {};
                            }
                            this.superQueryRefs[languageKey][index] = r;
                        }}
                        className="translate"
                    >{query}</div>
                    : <AutoComplete
                        invalid={syntaxInvalid ? true : false}
                        onSyntaxChecked={(val, wasEmptyOnFocus, isFocused) => {
                            this.onAfterTreeFetch(val, languageKey, index, wasEmptyOnFocus && isFocused);
                        }}
                        data-testid={`superQuery-language-${languageKey}`}
                        onFocus={(wasEmptyOnFocus) => {
                            let focusedQuery = { languageKey, index, wasEmptyOnFocus };
                            this.setState({focusedQuery});
                        }}
                        onBlur={() => {
                            this.debouncedSetQueryInvalid.flush();
                            this.setState({ focusedQuery: {} });
                            const sanitizedQuery = sanitizeQuery(query);
                            this.props.updateQueryGroup(languageKey, index, { title, query: sanitizedQuery, showVis })
                        }}
                        checkQuerySyntax={!showVis}
                        disabled={isDisabled || this.props.profileSharedBy !== null}
                        value={query}
                        onChange={(e) => {
                            this.setQueryInvalid(undefined, languageKey, index);
                            this.props.updateQueryGroup(languageKey, index, { title, query: e.target.value, showVis })
                        }}
                        label={t('Query')}
                        labelAppend={syntaxInvalid}
                        labelDisabled={isDisabled}
                        isArea isRequired
                        onSelection={val =>
                            this.onAutoCompleteStandardQuery(
                                query,
                                val,
                                (e) => { this.props.updateQueryGroup(languageKey, index, { title, query: e.target.value, showVis }) }
                            )
                        }
                    />
                }
            </div>
        // )}
        // </Draggable>
        )
    }

    getAllSelected(languageKey) {  // get all selected super queries
        return this.props.profile.queries[languageKey].superQuery.filter((q) => q.selected);
    }

    renderSuperQueryInput(languageKey, languageColor, selectedQueriesNumber) {
        const { profile } = this.props;
        const superQuery = _.sortBy(profile.queries[languageKey].superQuery, ['uiPosition']);

        if(
            languageKey === 'xx'
        ){
            return;
        }

        // translation method with fallback
        const t = this.props.t || (k => k);
        return (

            
            <section 
                className={styles.container}
                style={{
                    borderColor: languageColor
                }}
            >
            
                <div className={styles.queryWrap}>
                    <div className={styles.wrapper}>
                        <div className={styles.queriesHeader}>
                            <FontAwesomeIcon
                                style={{visibility:'hidden'}}
                                color='black'
                                className={[styles.infoBtn,].join(' ')}
                                title={t('query_type_description')}
                                // title
                                // todo: needs rework according to mlo: "query_type_description": "Welcome to the super profile set up assistant. Let's start by setting up the categories of your queries.\n\nRemember, 'alerts' are your main keywords and 'topic words' are only relevant if they appear together with an 'alert'. Super profile results occur for at least two 'alerts' or at least one 'alert' and one 'topic word'.\n\n'Discard words' filter out the content.",
                                icon={faInfoCircle}
                            />
                            <Button
                                disabled={this.props.profileSharedBy !== null}
                                type='primary'
                                data-testid={`selectAllQueries-${languageKey}`}
                                onClick={()=>{
                                    this.props.profile.queries[languageKey].superQuery.map((q, index) => {
                                        this.setState({ selectAll: !this.state.selectAll });
                                        this.props.selectQuery(!this.state.selectAll, languageKey, index);
                                    })
                                }}
                            >
                                <Checkbox checked={this.state.selectAll} text={this.props.t('Select all')} />
                            </Button>
                            <Button
                                disabled={selectedQueriesNumber === 0}
                                title={selectedQueriesNumber === 0 ? `${this.props.t('Please select at least one query to use this functionality')}.` : ''}
                                data-testid={`translateQueries-${languageKey}`}
                                type='primary'
                                onClick={() => {
                                    this.props.updateQueryTitleDup(languageKey);
                                    this.setState({ showTranslateModal: true });
                                }}
                            >
                                {this.props.t('Translate')}
                            </Button>
                            <div className={styles.newQueryBtn}> 
                                <span className='newQueryTitle'>{t('add group')}</span>
                                    <Button
                                        disabled={this.props.profileSharedBy !== null}
                                        data-testid={`addQueryGroup-${languageKey}`}
                                        type='secondary'
                                        onClick={()=>{
                                            this.props.addQueryGroup('alert', languageKey)
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            color='white'
                                            icon={faPlus}
                                        />
                                    </Button>
                            </div>
                        </div>
                        {/* <div className={styles.languageText} style={{backgroundColor: languageColor, borderColor: languageColor}}>
                            {t(languageKey)}
                        </div> */}
                        {
                                <Sortable
                                    isDraggable={
                                        // disabling drag of item for firefox while focus on any textfield within draggable item is active
                                        // see https://bugzilla.mozilla.org/show_bug.cgi?id=739071
                                        browserName !== BROWSER_FIREFOX || (this.state.isQueryTitleFocused === undefined && Object.keys(this.state.focusedQuery).length === 0) ? true : false
                                    }
                                    lists={this.queryTypes.length}
                                    // itemHoveredClassName={[styles.hovered].join(' ')}
                                    // itemDroppableClassName={[styles.droppable].join(' ')}
                                    renderList={(listIndex, renderItem) => {
                                        let queryType = this.queryTypes[listIndex];
                                        const queriesOfType = superQuery.filter((o)=>o.type === queryType.key);
                                        const renderList = ()=>(
                                            <div
                                                key={listIndex}
                                                className={[styles.category, styles[queryType.key]].join(' ')}
                                            >
                                            {queryType.title}
                                            <FontAwesomeIcon color='black' className={[styles.infoBtn,].join(' ')} title={queryType.description} icon={faInfoCircle} />
                                            {
                                                queriesOfType.length === 0 ?
                                                renderItem(
                                                    {queryGroup:{type:queryType.key}},
                                                    undefined,
                                                    listIndex,
                                                    () => {return <></>},
                                                    styles.categoryDroppableOverlay
                                                )
                                                :superQuery.map((queryGroup, index) => {
                                                    let oIdx;   // get 'original index' because superQuery variable is sorted by uiPosition
                                                    if(queryGroup.id !== null){
                                                        oIdx = _.findIndex(profile.queries[languageKey].superQuery, {id:queryGroup.id});
                                                    } else {
                                                        oIdx = _.findIndex(profile.queries[languageKey].superQuery, {client_id: queryGroup.client_id});
                                                    }
                                                    const qt = _.find(this.queryTypes, { key: queryGroup.type });
                                                    if (!queryGroup.delete && queryGroup.type === queryType.key) {
                                                        return renderItem(
                                                            {queryGroup, qt, oIdx, languageKey, id: queryGroup.client_id},
                                                            index,
                                                            listIndex,
                                                            () => {
                                                                return this.renderQueryGroup(
                                                                    qt.title,
                                                                    qt.key,
                                                                    queryGroup.title,
                                                                    queryGroup.query,
                                                                    oIdx,
                                                                    languageKey,
                                                                    queryGroup.showVis,
                                                                    queryGroup.disabled,
                                                                    queryGroup.id,
                                                                    queryGroup.spamCheck,
                                                                    queryGroup.spamStatus,
                                                                    this.getQueryInvalid(languageKey, oIdx),
                                                                    queryGroup.selected,
                                                                    queryGroup.dupTitle,
                                                                    queryGroup.queryEdited,
                                                                    )
                                                            }
                                                        )
                                                    }
                                                    else return undefined;
                                                })
                                            }
                                        </div>)
                                            return renderList();
                                    }}
                                    onDrop={(obj)=>{
                                        const src = obj.drag.item;
                                        const dst = obj.drop.item;
                                        this.props.updateQueryPosition(
                                            languageKey,
                                            src.queryGroup.uiPosition,
                                            dst.queryGroup.uiPosition,
                                            ()=>this.props.updateQueryGroupType(languageKey, src.oIdx, dst.queryGroup.type)
                                        );
                                    }}
                                ></Sortable>
                       
                        }

                    </div>
                </div>
            </section>
        );
    }

    checkTranslate(lang) {  // check if selected destination languages in 'translation' process would override existing queries and write info to this.state. if not: start translation ( this.translateSelectedQueries() )
        let showTranslateOverrideWarn = false;
        if(this.props.profile.isSuper){
            this.props.profile.queries[lang].superQuery.map((q,i) => {
                if (q.selected) {
                    this.state.translateTo.map(async (l) => {
                        const toQueryIdx = this.props.profile.queries[l.value].superQuery.findIndex((e) => e.title === q.title && !e.delete);
                        if (toQueryIdx !== -1) {
                            showTranslateOverrideWarn = true;
                            let updatedQuery = {
                                ...this.props.profile.queries[l.value].superQuery[toQueryIdx],
                                tWouldOverride: true,
                            };
                            this.props.updateQueryGroup(l.value, toQueryIdx, updatedQuery);
                        }
                    })
                }
            })
        } else {
            this.state.translateTo.map(async (l) => {
                const q = this.props.profile.queries[l.value].standardQuery.query;
                if(q){
                    showTranslateOverrideWarn = true;
                    this.props.updateStandardQuery(l.value, {tWouldOverride: true});
                }
            });
        }

        if (showTranslateOverrideWarn) {
            this.setState({
                showTranslateOverrideWarn
            });
        } else {
            this.translateSelectedQueries(lang);
        }
    }

    async translateSelectedQueries(lang) {
        // request translations for all selected queries
        let translateFailed = [];
        let requests = [];
        if(this.props.isSuper){
            await Promise.all(this.props.profile.queries[lang].superQuery.map(async(q, i) => {
                if (q.selected) {
                    await Promise.all(this.state.translateTo.map(async (l) => {
                        const toQueryIdx = this.props.profile.queries[l.value].superQuery.findIndex((e) => e.title === q.title && !e.delete);
                        const query = this.props.profile.queries[l.value].superQuery[toQueryIdx];
                        const shouldFetch = toQueryIdx === -1 || !query.tWouldOverride || query.tOverride;

                        if (shouldFetch) {
                            requests.push(async() => {
                                const translated = await APITranslations.translate(q.query, lang, l.value);
                                if (!translated || !translated.text) {
                                    translateFailed.push({title: q.title, language: l.value});
                                    this.setState((state) => ({ translateCallsDone: state.translateCallsDone + 1 }));
                                    return;
                                }
                                let queryValid = await APIQueryVisualization.isQuerySyntaxValid(translated.text);
                                if (toQueryIdx === -1) {    // add new query if query with title does not exist in destination language
                                    this.props.addQueryGroup(q.type, l.value, q.title, translated.text, queryValid);
                                } else if(!query.tWouldOverride || query.tOverride) {    // update query with same title in destination language
                                    let updatedQuery = { ...this.props.profile.queries[l.value].superQuery[toQueryIdx], query: translated.text, isValid: queryValid };
                                    this.props.updateQueryGroup(l.value, toQueryIdx, updatedQuery);
                                }
                                this.setState((state) => ({ translateCallsDone: state.translateCallsDone + 1 }));
                            });
                        }
                    }))
                }
            }))
        } else {
            const srcQuery = this.props.profile.queries[lang].standardQuery;
            await Promise.all(this.state.translateTo.map(async (l) => {
                const query = this.props.profile.queries[l.value].standardQuery;
                const shouldFetch = !query.tWouldOverride || query.tOverride;
                let translated;
                if (shouldFetch) {
                    requests.push(async () => {
                        translated = await APITranslations.translate(srcQuery.query, lang, l.value);
                        if (!translated || !translated.text) {
                            translateFailed.push({title: query.title, language: l.label});
                            this.setState((state) => ({ translateCallsDone: state.translateCallsDone + 1 }));
                            return;
                        }
                        this.props.updateStandardQuery(l.value, { query: translated.text });
                        this.setState((state) => ({ translateCallsDone: state.translateCallsDone + 1 }));
                    });
                }
            }));
        }

        this.setState({ translateCallsTotal: requests.length });
        await sendRequests(requests, 5);
        this.setState({ translateCallsTotal: 0, translateCallsDone: 0 });

        if (translateFailed?.length > 0) {
            this.setState({ translateFailed });
        } else {
            this.setState({ translateFailed, showTranslateOverrideWarn: undefined, langWithQueries: [], translateTo: [], translateDone: true });
        }
    }

    resetTOverride(){
        Object.keys(this.props.profile.queries).map(l => {
            if(this.props.isSuper){
                if(this.props.profile.queries[l].superQuery){
                    this.props.profile.queries[l].superQuery.map((q,i)=>{
                        if(q.tWouldOverride !== undefined){
                            this.props.updateQueryGroup(l, i, {
                                ...q,
                                tWouldOverride: undefined, 
                                tOverride: undefined
                            });
                        }
                    })
                }
            } else {
                if(this.props.profile?.queries[l]?.standardQuery){
                    this.props.updateStandardQuery(l,{
                        tWouldOverride:undefined,
                        tOverride: undefined
                    });
                }
            }
        })
    }

    renderWouldOverrideRow(query, language, queryIndex, isSuper){
        return (<tr key={`${language}${queryIndex}`}>
            {isSuper && <td>{query.title}</td>}
            <td>{language}</td>
            <td>
                <LabeledSwitch
                    checked={query.tOverride ? query.tOverride : false}
                    onChange={(val) => isSuper
                        ? this.props.updateQueryGroup(language,queryIndex,{...query, tOverride: val})
                        : this.props.updateStandardQuery(language, {tOverride: val})
                    }
                />
            </td>
        </tr>)
    }

    render() {
        const { t, 
            // supportedLanguages
         } = this.props;
        const {
            profile,
            showSuper
        } = this.props;

        if (!profile || profile.queries.length <= 0) {
            return (
                <InfoBox
                    type='danger'
                    text={t("Please select a channel group with channels in it to proceed!")}
                />
            );
        }

        let languages = this.state.languages;

        languages = languages.filter(l => {
            if (!profile.queries[l]) return false;
            let query = showSuper ? profile.queries[l].superQuery : profile.queries[l].standardQuery;
            return (
                query
                && (!showSuper || l !== 'xx')
            )
        });

        let colors = [];
        languages.forEach(language => {
            switch (language) {
                case 'xx': colors.push('rgba(215, 139, 33, 0.7)'); break;
                case '**': colors.push('rgba(128, 173, 119, 0.6'); break;
                default: colors.push('rgba(11, 40, 119, 0.5)'); break;
            }
        });
        if (languages.length === 0) return null;
        const languageOptions = languages.map(langKey => ({ label: t(langKey), value: langKey }));
        const activeTab = this.props.activeTab;

        let selected;
        const lang = showSuper ? languages[this.props.activeTab] : this.state.activeLangStandard;
        if (showSuper && lang !== 'xx') {
            selected = this.getAllSelected(lang);
        }
        
        return (
            <section>
                {this.state.translateCallsTotal > 0 && this.state.translateCallsDone < this.state.translateCallsTotal &&
                    <LoadingIndicator
                    blockContent={true}
                    text={`${this.props.t('profile_queries_translating')} (${this.state.translateCallsDone}/${this.state.translateCallsTotal})`}
                    progress={this.state.translateCallsDone/this.state.translateCallsTotal*100}
                />
                }
                {this.state.showTranslateModal && this.props.profile.hasDupTitles &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={() => { this.setState({ showTranslateModal: undefined }); }}
                    >
                        <h2>{this.props.t('profile_dup_qtitle')}</h2>
                        <div>{`${this.props.t('profile_dup_qtitle_description')} ${this.props.t('profile_dup_qtitle_remove')}`}</div>
                    </Popup>
                }
                {this.state.showTranslateModal && !this.props.profile.hasDupTitles &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onCancel={() => { this.setState({ showTranslateModal: undefined, translateTo: [] }) }}
                        okTxt={`${this.props.t('Translate')}!`}
                        onOk={() => { 
                            this.setState({ showTranslateModal: undefined }); 
                            this.checkTranslate(lang);
                        }}
                        okDisabled={!this.state.translateTo || this.state.translateTo.length === 0}
                        okTitle={!this.state.translateTo ? this.props.t('Please select at least one language') : ''}
                    >
                        <div>
                            <h2>{this.props.t('Translate')}</h2>
                            <p>{`${this.props.t('Number of selected queries')}: ${this.props.profile.isSuper ? (selected && selected.length) : 1}`}</p>
                            <p>{`${this.props.t('Please select the languages to which you want to translate them')}.`}</p>
                            <p>{`${this.props.t('translation_unsupported_lang_text')}:`}</p>
                            <div data-testid="select-component-translation">
                            <SelectAsync
                                selectAll={true}
                                instanceId={`translationList`}
                                isMulti={true}
                                options={this.state.languages.filter(l => l !== lang && l !== '**').map(l => {
                                    const isDisabled = !APITranslations.supportedLanguages.includes(l);
                                    return ({
                                        label: `${l} - ${this.props.t(l)}`,
                                        value: l,
                                        isDisabled,
                                    });
                                }).sort((a, b) => a.value > b.value && 1 || -1)}
                                value={this.state.translateTo}
                                onChange={(val)=>this.setState({translateTo: val})}
                                closeMenuOnSelect={false}
                            />
                            </div>
                        </div>
                    </Popup>
                }
                {this.state.showTranslateOverrideWarn &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onCancel={() => {
                            this.setState({ showTranslateOverrideWarn: undefined, langWithQueries:[], translateTo:[] })
                            this.resetTOverride();
                        }
                        }
                        okTxt={`${this.props.t('Save')}!`}
                        onOk={async () => {
                            this.setState({ showTranslateOverrideWarn: undefined, langWithQueries: [], translateTo: [] });
                            this.translateSelectedQueries(lang);
                        }}
                    >
                        <div style={{height: '100%', overflow: 'auto'}}>
                            <h2>{this.props.t('query_overwrite')}</h2>
                            <p>{`${this.props.t('query_overwrite_text')}`}</p>
                            {
                                <StyledTable>
                                    <table className={styles.table}>
                                        <thead>
                                            <tr>
                                                {showSuper && <th>{this.props.t('query title')}</th>}
                                                <th>{this.props.t('language')}</th>
                                                <th>{this.props.t('overwrite')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(this.props.profile.queries).map(l => {
                                                if(showSuper){
                                                    if(this.props.profile.queries[l].superQuery){
                                                        return this.props.profile.queries[l].superQuery.map((q,i)=>{
                                                            if(q.tWouldOverride){
                                                                return this.renderWouldOverrideRow(q, l, i, true);
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    let q = this.props.profile.queries[l].standardQuery;
                                                    if(q.tWouldOverride){
                                                        return this.renderWouldOverrideRow(q, l);
                                                    }
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </StyledTable>
                            }
                        </div>
                    </Popup>
                }
                {
                    this.state.translateDone &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={async () => {
                            this.setState({ translateDone: false });
                            this.resetTOverride();
                        }}
                    >
                        <div>
                            <h2>{this.props.t('translation_completed')}</h2>
                            <p>{`${this.props.t('profile_translation_completed_text')}`}</p>
                            {this.state.langWithQueries.map((l) => (<div>{l}</div>))}
                        </div>
                    </Popup>
                }
                {this.state.translateFailed?.length > 0 &&
                    <Popup
                        size='auto'
                        blockContent={true}
                        onOk={() => { this.setState({ translateFailed: [] }); }}
                    >
                        <div style={{height: '100%', overflow: 'auto'}}>
                        <h2>{this.props.t('profile_translation_failed')}</h2>
                        <p>{`${this.props.t('profile_translation_failed_text')}`}</p>
                            {showSuper ?
                                <StyledTable>
                                    <table className={styles.table}>
                                        <thead>
                                            <tr>
                                                {<th>{this.props.t('query title')}</th>}
                                                <th>{this.props.t('language')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.translateFailed.map(failed => {
                                                return (<tr>
                                                    <td>{failed.title}</td>
                                                    <td>{failed.language}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </StyledTable>
                                : <div>
                                    {this.props.t('Affected Languages')}: <ul>{this.state.translateFailed.map(failed => <li>{failed.language}</li>)}</ul>
                                </div>}
                            
                        </div>
                    </Popup>
                }
                { showSuper
                    ? 
                        <div key={activeTab}>
                            <TabBar
                                tabs={
                                languages.map((l) => {
                                        return (<>
                                            {l} <IconCount showZero={true} count={(profile.queries[l].superQuery.filter(q => q.delete !== true).length)} />
                                            { profile.queries[l].superQuery.some(query => query.isValid === false || query.spamStatus > 0) &&
                                                <FontAwesomeIcon title={t('queries_need_attention')} icon={faExclamationTriangle} style={{ color: 'red' }} />
                                            }
                                        </>)
                                    })
                                }
                                active={activeTab}
                                maxTabs={10}
                                height='40px'
                                onClick={
                                    (tabIdx) => {
                                        this.setState({ selectAll: undefined });
                                        this.props.setActiveTab(tabIdx);
                                    }
                                }
                            />
                            {this.renderSuperQueryInput(languages[activeTab], colors[activeTab], selected && selected.length)}
                        </div>   
                    :
                        
                        _.map(languages, (language,idx) => {
                            return <div
                                key={idx}
                                // className={!langSupport && styles.noLangSupport}
                                // title={!langSupport && t('profile_no_lang_support')}
                            >
                                <Query
                                    languageKey={language}
                                    languageColor={colors[idx]} 
                                    languageOptions={languageOptions}
                                    queryObj={profile.queries[language]}
                                    onStandardQueryChange={this.props.onStandardQueryChange}
                                    onLangChange={this.props.onLangChange}
                                    disableQuery={this.props.disableQuery}
                                    removeLanguage={this.props.removeLanguage}
                                    setQueryInvalid={this.setQueryInvalid}
                                    showQueryTree={this.showQueryTree}
                                    onAutoCompleteStandardQuery={this.onAutoCompleteStandardQuery}
                                    onSyntaxChecked={(val, wasEmptyOnFocus, isFocused) => {
                                        this.onAfterTreeFetch(val, language, 0, wasEmptyOnFocus && isFocused)
                                    }}
                                    onAfterTreeFetch={(val) => {
                                        this.onAfterTreeFetch(
                                            val,
                                            language,
                                            undefined,
                                            this.state.focusedQuery.languageKey === language
                                            && this.state.focusedQuery.wasEmptyOnFocus
                                        )
                                    }}
                                    queryInvalid={this.getQueryInvalid(language,0)}
                                    showTree={this.state.showTree}
                                    tryQuery={this.props.tryQuery}
                                    renderSpamFailed={this.props.renderSpamFailed}
                                    onBlur={() => {
                                        this.debouncedSetQueryInvalid.flush();
                                        this.setState({ focusedQuery: {} });
                                    }}
                                    onFocus={(wasEmptyOnFocus) => {
                                        let focusedQuery = { languageKey: language, wasEmptyOnFocus };
                                        this.setState({focusedQuery});
                                    }}
                                    onLmuStatusChange={this.props.onLmuStatusChange}
                                    runSpamCheck={()=>{ this.props.runSpamCheck(this.props.queries[language],language); }}
                                    setShowTranslateModal={(bool)=>this.setState({ showTranslateModal: bool })}
                                    setActiveLang={(languageKey)=>(this.setState({activeLangStandard: languageKey}))}
                                    profileSharedBy={this.props.profileSharedBy}
                                    saveProfile={this.props.saveProfile}
                                />
                            </div>
                        })
                        
                }
            </section>
        );
    }
}



const mapStateToProps = state => ({ theme: state.theme, matomo: state.matomo });
export default connect(mapStateToProps)(withTranslation()(QueryInput)) ;