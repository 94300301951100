import React, { useEffect, useState } from 'react';
import styles from './ThemeSelector.module.scss';
import classNames from 'classnames';
//import APIMe from '../../API/APIMe';
import { updateTheme } from '../../redux/actions/actions.theme';
import { connect } from 'react-redux';
import themes from '../../assets/themes/themes';
import APISettings from 'src/API/APISettings';


const ThemeSelector = ({ updateTheme, onThemeChanged, selectedTheme }) => {
  const [selected, setSelected] = useState(
    // 
  );

  useEffect(()=>{
    setSelected(selectedTheme);
  }, [selectedTheme]);

  const setTheme = (name, theme) => {
    setSelected(name);
    onThemeChanged(name);
    updateTheme(theme); // update redux store to allow user to see immediately how the theme looks in the application - even without saving
  }

  return (
    <div className={styles.wrap}>
      {
        Object.entries(themes).map(([name, theme], index) =>
          <div
            key={index}
            data-testid={`themeselector-${name}`}
            className={classNames(
              styles.block,
              {[theme.borderPrimary]: name === selected}
            )}

            onClick={() => setTheme(name, theme)}
          >
            <div className={theme.backgroundPrimary} />
            <div className={theme.backgroundSecondary} />

            <div className={styles.buttons}>
              <div className={theme.backgroundSuccess} />
              <div className={theme.backgroundDanger} />
            </div>
          </div>
        )
      }
    </div>
  )

}

export default connect(null, { updateTheme })(ThemeSelector);