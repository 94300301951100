import _ from 'lodash';
import { SET_SEARCH_HISTORY_CLEANUP_RUNNING } from '../types/actionTypes.runningOperations';

const runningOperationsStateReducers = (state = {
    isHistoryCleanupRunning: false,
}, action) => {
    switch (action.type) {
        case SET_SEARCH_HISTORY_CLEANUP_RUNNING:
            return {...state, isHistoryCleanupRunning: action.data};
        default:
            return state;
    }
};

export default runningOperationsStateReducers;