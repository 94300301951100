import { useTranslation } from "react-i18next"
import FormFooter from "src/Widgets/common/basicElements/FormFooter/FormFooter";
import styles from "../ChannelGroupsNext.module.scss";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { addChannelsChunked, addGroup, deleteGroup, getGroups, updateGroup } from "src/redux/actions/actions.channelGroups";
import { useEffect, useState } from "react";
import AdminRow from "src/Widgets/ChannelGroups/components/AdminRow/AdminRow";
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";
import DeleteRow from "src/Widgets/common/basicElements/DeleteRow/DeleteRow";
import DuplicateRow from "../components/DuplicateRow/DuplicateRow";

const useEditChannelGroup = (selectedGroup: any, selectGroupHandler: any, setIsEditViewOpen: any) => {

    const isAdminUser = false;
    const channelGroups = useAppSelector((state) => state.channelGroupReducers);

    const [editedTitle, setEditedTitle] = useState<string>('');
    const [isAdminGroup, setIsAdminGroup] = useState<boolean>(false);
    const [saveStatus, setSaveStatus] = useState<any>({
        isSuccess: false,
        isError: false,
    });
    const [deleteStatus, setDeleteStatus] = useState<any>({
        isSuccess: false,
        isError: false,
    });

    const [duplicateStatus, setDuplicateStatus] = useState({
        isSuccess: false,
        isError: false
    })

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const profileGroups = useAppSelector(state => state.profileGroups?.data)

    //Save group title changes to backend and show success or error message
    const saveButtonHandler = () => {
        const res = dispatch(
            updateGroup(selectedGroup.id, { title: editedTitle.trimEnd() }, [90121])
        );
        res.then((res: any) =>
            res?.rc === 0
                ? setSaveStatus({ ...saveStatus, isSuccess: true })
                : setSaveStatus({ ...saveStatus, isError: true })
        );
        setTimeout(() => {
            setIsEditViewOpen(false);
        }, 400)
    };

    //Delete group
    const deleteGroupHandler = () => {
        dispatch(deleteGroup(selectedGroup.id, [90105]))
        .then((res: any) => {
            const usedProfiles = res.usedByProfiles?.map((p: any) => p)
            const usedProfileTitles = profileGroups?.flatMap((group: any) => {
                return group?.profiles?.filter((profile: any) => {
                    return usedProfiles?.includes(profile.id)
                }).map((profile: any) => profile.title)
            })

            const uniqueUsedProfileTitles = [...new Set(usedProfileTitles)];

            if (res?.rc === 0) {
                setDeleteStatus({ ...deleteStatus, isSuccess: true });
                dispatch(getGroups())
            } else {
                setDeleteStatus({ ...deleteStatus, isError: true });
              const displayProfileTitles = uniqueUsedProfileTitles.slice(0, 5).map(title => "- " + title);
              if (uniqueUsedProfileTitles.length > 5) displayProfileTitles.push("- ......");
            }
          });
          setIsEditViewOpen(false);
          selectGroupHandler({});
    };

    const duplicateGroupHandler = async (duplicateInput: string) => {
        channelGroups?.some((channel: any) => channel?.title !== duplicateInput) &&
        await dispatch(addGroup({ title: duplicateInput?.trimEnd() }, [90102]))
          .then((res: any) => {
            if (res.rc === 0) {
              setDuplicateStatus({...duplicateStatus, isSuccess: true})
              dispatch(addChannelsChunked(res.data.id, selectedGroup.channels));
              setDuplicateStatus({isError: false, isSuccess: false});
            } else {
                setDuplicateStatus({...duplicateStatus, isError: true});
            }
          });
          dispatch(getGroups())
          selectGroupHandler({});
          setIsEditViewOpen(false);
      } 

    //Fill the input with the selected group title
    useEffect(() => {
        setEditedTitle(selectedGroup.title);
    }, [selectedGroup])

    //Change the title when input changes
    const inputChangeHandler = (e: any) => {
        setEditedTitle(e.target.value);
    }

    //Edit view for channel group edit container
    const editView = (
        <div className={styles.editViewContainer} data-testid="edit-view-container">
            
            <div className={styles.middleSection}>
            <FormFooter
                onGoBack={() => setIsEditViewOpen(false)}
                onAction={saveButtonHandler}
                //Save button disabled if its same or empty
                actionDisabled={
                    !editedTitle ||
                    (editedTitle === selectedGroup.title &&
                        (!editedTitle || !isAdminGroup))
                }
                actionLabel={t('Save Changes')}
                success={saveStatus.isSuccess ? t('Successfully created') : ''}
                fail={saveStatus.isError ? t('Could not save') : ''}
            />
                {isAdminUser && (
                    <AdminRow
                        setIsAdminGroup={setIsAdminGroup}
                        isAdminGroup={isAdminGroup}
                    />
                )}
                <h3>{t('Title')}</h3>
                <TextInput
                    value={editedTitle}
                    onChange={inputChangeHandler}
                    label={t('Title')}
                    placeholder={t('Enter Title')}
                    isRequired
                    className={styles.groupEditInput}
                />
                {
                    //@ts-ignore
                    <DeleteRow
                        labelText={t('Remove Group')}
                        onClick={deleteGroupHandler}
                        confirmationText={t('Really delete group?')}
                        deletePopup
                    />
                }
                <DuplicateRow
                    labelText={t('Duplicate Channel Group')}
                    duplicateFunction={duplicateGroupHandler}
                    duplicateStatus={duplicateStatus}
                    externalClass={styles.duplicateInput}
                />
            </div>
        </div>
    );

return {
    editView
}
}

export default useEditChannelGroup