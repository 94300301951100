import GenericCalls from './genericCalls';

class APIMentions {

    static async getMentionResults(profileIds, startDate, endDate, type) {
        const url = `/api/analytics/_all/select/profiles/${encodeURIComponent(profileIds)}/types/${encodeURIComponent(type)}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async getOverviewMentions(profileIds, startDate, endDate) {
        const url = `/api/analytics/_all/select/profiles/${encodeURIComponent(profileIds)}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }
///api/text/for/notif/554221547/snippet.json
    static async getNotificationResults(notificationId, hideErrors) {
        const url = `/api/text/for/notif/${notificationId}/snippet.json`
        return await GenericCalls.get(url, undefined, undefined, hideErrors);
    }

    static async getNotificationText(notificationId){
        const url = `api/text/for/notif/${notificationId}/text`
        return await GenericCalls.get(url);
    }

    static async getComputedClustersData(notifIds){
        const url = `/api/clusters/for/notif/${notifIds}`
        return await GenericCalls.get(url);
    }
}

export default APIMentions;