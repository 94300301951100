import React, { FC } from 'react'
import styles from "./SortClickable.module.scss"
import { selectSortType, sortChannels } from 'src/redux/actions/actions.channelGroupsWidget';
import { useTranslation } from 'react-i18next';
import { SortClickableProps } from './SortClickable.types';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';


const SortClickable: FC<SortClickableProps> = ({sortData}) => {
    const { allChannelsShown, selectedFilter, selectedSortType, filterBoxIndex } =
        useAppSelector((state: any) => state.channelGroupsWidgetReducer);
    const selectedSort = selectedSortType?.[filterBoxIndex] || ''
    const dispatch = useAppDispatch()

    const {t} = useTranslation()

    const sortTypes: string[] = [
      t("Sort Ascending"),
      t("Sort Descending")
    ]

    const sortHandler = (order: 'asc' | 'dsc', filter: string) => {
      const normalizedFilter = filter?.toLowerCase();
      const sortedChannels = sortData?.sort((a: any, b: any) => {
        const propA = allChannelsShown 
            ? a?.[normalizedFilter]?.toLowerCase()
            : a?.[filter]?.toLowerCase();
        const propB = allChannelsShown 
            ? b?.[normalizedFilter]?.toLowerCase()
            : b?.[filter]?.toLowerCase();
        const comparison = propA?.localeCompare(propB || '');
        return order === "asc" ? comparison : -comparison;
      });
      dispatch(sortChannels(sortedChannels)); 
    };
    
    const handleSortClick = (sortType: string, index: number) => {
      dispatch(selectSortType(index, sortType))
      switch(sortType){
        case "Sort ascending":
          return sortHandler("asc", selectedFilter)
        case "Sort descending":
          return sortHandler("dsc", selectedFilter)
        default:
          return  
      }
    };

    return (
      <div className={styles.sortClickableArea}>
        {sortTypes?.map((sortType) => (
          <button
          key={sortType}
          data-testid={`cg-sortType-${sortType}`}
          className={selectedSort === sortType ? styles.selected : styles.nonSelected}
          onClick={() => handleSortClick(sortType, filterBoxIndex)}
        >
          {sortType}
        </button>
        
        ))}
      </div>
    );
}

export default SortClickable
