import React from 'react';
import styles from './ErrorBoundary.module.scss';
import { withTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { onError } from 'src/errorReporting';

// see also https://react.christmas/2018/14,
// https://reactjs.org/docs/error-boundaries.html

class ErrorBoundary extends React.Component {
    state = {
        error: null,
    };

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, info) {
        onError(`widget-crashed: ${error?.name}: ${error?.message}`, error, 'widget-crashed');
        /* Notes:
         -  calling onError() not needed in dev. env. - but in production build, window.onerror (and therefore the onError() method) is not 
            called when catched by ErrorBoundary component. Therefore we call it again in this case.
         -  error.message will show variable/funct/... names from production build. there is no way to get the "original" names from source in this case (but we have stacktrace)
        */
    }

    render() {
        const { t } = this.props;
        if (this.state.error) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <h2>{t('generic_error_msg')}</h2>
                        <Button
                            style={{ width: '100px' }}
                            onClick={() => { this.setState({ error: undefined }) }}
                            type="primary">
                            {t('reload')}
                        </Button>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);