import GenericCalls from './genericCalls';
import _ from 'lodash';
import { dateFromSettings, dateToSettings } from 'src/Widgets/common/helpers';
// import config from 'src/config/config';

class APISettings{
    constructor(){
        this.baseURL = `/api/settings/`;
    }
    async handleNonExisting(key, data){    // creates settings key if not already existing ?
        // TODO: call postList(key) (not implemented on apid yet)
        
        // const res = await APISettings.postList(key);
        // if(res.rc === 0){
        //     APISettings.postItem(key, data);
        // }
    }

    async getLists() {
        const list = await GenericCalls.get(this.baseURL);
        return list;
    }

    async postList(key) {
        return await GenericCalls.post(this.baseURL, {key});
    }

    async getItems(key) {
        const list = await GenericCalls.get(this.baseURL + key);
        list.data = _.map(list.data, item => {
            return { ...dateFromSettings(JSON.parse(item.value)), id: item.id };
        });
        return list;
    }

    async getItem(key, id) {
        let url = `${this.baseURL}${key}/${id}`;
        let res = await GenericCalls.get(url);
        res = res.data[0];
        if(res === undefined) {
            return undefined;
        }
        res.value = JSON.parse(res.value);
        res.value = dateFromSettings(res.value);
        res = {
            ...res.value,
            dbId: res.id
        }
        return res;
    }

    async deleteList(key) {
        return await GenericCalls.delete(this.baseURL +key);
    }

    async postItem(key, data, httpErrorsNoUserInfo, hideErrors) {
        const res = await GenericCalls.post(this.baseURL + key, {
            value: JSON.stringify({
                ...data,
                // version: config.CLIENT_SETTINGS_VERSION
            })
        }, undefined, hideErrors, undefined, httpErrorsNoUserInfo);
        switch(res.rc){
            case 930004: return APISettings.handleNonExisting(key, data);
            default: return res;
        }
    }

    async putItem(key, id, data) {
        data = dateToSettings(data);
        return await GenericCalls.put(this.baseURL + key + '/' + id, { value: JSON.stringify({
            ...data,
            // version: config.CLIENT_SETTINGS_VERSION
        }) });
    }

    // static async put(id, data) {
    //     return await GenericCalls.put('/api/settings/' + id, data, APISettings.callSettings);
    // }

    async deleteItem(key, id, httpErrorsNoUserInfo, hideRCs) {
        return await GenericCalls.delete(this.baseURL +key + '/' + id, hideRCs, httpErrorsNoUserInfo);
    }

    async deleteAllItemsFromList(key) {
        const items = (await this.getItems(key)).data;
        await Promise.all(items.map(async (i) => {
            await this.deleteItem(key, i.id);
            console.log(i)
        }));
    }

    async deleteAllSettings() {
        // let lists = await this.getLists();
        let lists = {data: ["workspaces", "widgets", "epgHistory", "searchHistory"]};
        if(!lists || !lists.data) return false;
        lists = lists.data;
        await Promise.all(await lists.map(async (list) => {
            await this.deleteAllItemsFromList(list);
        }));
        return lists.toString();
    }

    async sendResetPasswordLink(username, hideErrors=[]) {
        const res = await GenericCalls.get(
            `/cgi-bin/requestPwdLink?username=${username}&outputType=JSON&forV3=1`,
            undefined,
            undefined,
            hideErrors
        );
        return res;
    }
}

export default new APISettings();
export {APISettings};