import APIChannelGroups from '../../API/APIChannelGroups';
import {
  RECEIVE_ITEMS, UPDATED_ITEM, DELETED_GROUP, ADDED_GROUP,
  ADDED_CHANNELS, REMOVED_CHANNELS,
  ADDED_CHANNELGROUPS, REMOVED_CHANNELGROUPS
} from '../types/actionTypes.channelGroups';
import _ from 'lodash';

// NOTE: cleanup, convert these functs below to also be contained within the functs that call dispatch()
export const receivedItems = (data) => ({
    type: RECEIVE_ITEMS,
    data: data
});

export const updatedItem = (id, data) => ({
  type: UPDATED_ITEM,
  data: data,
  id: id
});

export const deletedGroup = (id) => ({
  type: DELETED_GROUP,
  id: id
});

export const addedGroup = (id, data) => ({
  type: ADDED_GROUP,
  id: id,
  data: data
});


export const getGroups = () => {
  return (dispatch) => {
    return APIChannelGroups.get()
      .then((res) => {
        dispatch(receivedItems(res.data));
        return res.data;
      });
  }
};

export const getAdminGroups = () => {
  return (dispatch) => {
    return APIChannelGroups.getAdminGroups()
      .then((res) => {
        dispatch(receivedItems(res.data));
        return res.data;
      });
  }
}


export const addGroup = (data, hideErrors) => {
  return (dispatch) => {
    return APIChannelGroups.post(data, hideErrors).then((res) => {
      if(res.rc === 0){
        dispatch(addedGroup(res.data.id, data));
      }
      return res;
    })
  }
};

export const updateGroup = (id, data, hideErrors) => {
  return (dispatch) => {
      return APIChannelGroups.put(id, data, hideErrors).then((res) => {
        if(res.rc === 0){
          dispatch(updatedItem(id, data));
        }
        return res;
    })
  }
};

export const addChannelsChunked = (id, channelsArr) => {
  return (dispatch) => {
    if(channelsArr.length === 0) return;   
    return APIChannelGroups.addChannelsChunked(id, channelsArr).then((responses) => {        
      responses?.forEach((res) => {
        if(res?.response?.rc === 0){
          dispatch({
            type: ADDED_CHANNELS,
            data: res?.channels,
            id: id
          });
        }
      });
      return responses?.map((res) => res?.response);
    })
  }
};

export const removeChannelsChunked = (id, channelsArr) => {
  return (dispatch) => {
    if(channelsArr.length === 0) return;
    return APIChannelGroups.removeChannelsChunked(id, channelsArr).then((responses) => {
      responses?.forEach((res) => {
        if (res?.response?.rc === 0) {
          dispatch({
            type: REMOVED_CHANNELS,
            data: res?.channels,
            id: id
          });
        }
      });
      return responses?.map((res) => res?.response);
    })
  }
};

export const addChannelGroups = (id, channelsArr) => {
  return (dispatch) => {
    if(channelsArr.length === 0) return;
      return APIChannelGroups.addGroups(id, _.map(channelsArr,(c)=>c.id)).then((res) => {
        dispatch({
          type: ADDED_CHANNELGROUPS,
          data: channelsArr,
          id: id
        });
        return res.data;
    })
  }
};

export const removeChannelGroups = (id, channelsArr) => {
  return (dispatch) => {
    if(channelsArr.length === 0) return;
      return APIChannelGroups.removeGroups(id, _.map(channelsArr,(c)=>c.id)).then((res) => {
        dispatch({
          type: REMOVED_CHANNELGROUPS,
          data: channelsArr,
          id: id
        });
        return res.data;
    })
  }
};

export const deleteGroup = (id, hideErrors) => {
  return (dispatch) => {
      return APIChannelGroups.delete(id, hideErrors).then((res) => {
        dispatch(deletedGroup(id));
        return res;
    })
  }
};