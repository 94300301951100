// endpoints list generated using printEndpoints() helper function from our openapi specs

import { urlParamsToObj } from "src/Widgets/common/helpers";

const endpoints = [
    "/api/me",
    "/api/me/company",
    "/api/me/company/users",
    "/api/me/language",
    "/api/me/share-groups",
    "/api/me/channel-groups",
    "/api/users",
    "/api/users/{id}",
    "/api/users/{id}/feed-groups",
    "/api/users/search/{searchTerm}",
    "/api/companies",
    "/api/companies/{id}",
    "/api/companies/{id}/share-groups",
    "/api/companies/search/{searchTerm}",
    "/api/channels",
    "/future/api/channels",
    "/api/channels/filters",
    "/api/channels/{id}",
    "/api/epg",
    "/api/epg/search/{searchTerm}",
    "/api/settings",
    "/api/settings/{key}",
    "/api/settings/{key}/{id}",
    "/api/profiles",
    "/api/profiles/{id}",
    "/api/profiles/{id}/share-groups",
    "/api/profiles/{id}/share-groups/{shareGroupIds}",
    "/api/deleted/profiles",
    "/api/deleted/profiles/{id}/restore",
    "/api/deleted/profiles/{id}",
    "/api/profile-groups",
    "/api/profile-groups/{id}",
    "/api/profile-groups/search/{searchTerm}",
    "/api/deleted/profile-groups",
    "/api/deleted/profile-groups/{id}/restore",
    "/api/deleted/profile-groups/{id}",
    "/api/channel-groups",
    "/api/channel-groups/admin",
    "/api/channel-groups/{id}",
    "/api/channel-groups/{id}/languages",
    "/api/channel-groups/{id}/channels/{channelIds}",
    "/api/channel-groups/{id}/groups/{groupIds}",
    "/api/channel-groups/search/{searchTerm}",
    "/api/company-groups",
    "/api/company-groups/{id}",
    "/api/share-groups",
    "/api/share-groups/{id}",
    "/api/share-groups/{id}/users/{userIds}",
    "/api/feed-groups",
    "/api/feed-groups/{id}",
    "/api/feed-groups/{id}/users/{userIds}",
    "/api/notifications/edited",
    "/api/notifications/edited/{id}",
    "/api/clipinfo/{id}",
    "/api/statistics/profiles/{profileId}",
    "/api/statistics/profiles",
    "/api/statistics/profilegroups",
    "/api/statistics/editorial/categories",
    "/api/statistics/editorial/users",
    "/api/statistics/editorial/profiles",
    "/api/statistics/xlsx",
    "/api/news/{readStatus}",
    "/api/news/{readStatus}/{id}",
    "/api/search",
    "/api/logo-families",
    "/api/logo-families/{id}",
    "/api/logos",
    "/api/logos/{id}",
    "/api/auth/check",
    "/api/autocomplete/{word}",
    "/api/options/timezones",
    "/api/query/tree",
    "/api/telemetry/console/error",
    "/api/telemetry/console/warning",
    "/api/pushfeed",
    "/api/feed/pushFeed",
    "/api/feed/eFeed",
    "/api/schedule",
    "/api/schedule/{id}",
    "/api/translation/deepl/translate",
    "/api/translation/google/translate",
    "/api/profiles/{id}/{queryId}/spamCheck",
    "/autocomplete",
    "/api/text/for/notif/{id}/{type}"
]

// removes url parameters and returns pattern instead of url - if matching any pattern from list of endpoints
// e.g. input /api/users/397?someVar=value1 will return /api/users/{id}
export function convertUrlToGeneric(url: string, endpoints: string[]) {
    const splittedUrl = url.split('?');
    const baseUrl = splittedUrl[0];
    const endpoint = findMatchingEndpoint(endpoints, baseUrl);
    
    return endpoint ?? baseUrl;
}

export function findMatchingEndpoint(endpoints:string[], url:string) {
    for (let endpoint of endpoints) {
        // Escape special characters in endpoint template and replace dynamic part with a regex pattern
        let regexPattern = endpoint.replace(/\{([^}]+)\}/g, '([^/]+)');
        regexPattern = `^${regexPattern}$`;
        
        // Create a regular expression from the template
        let regex = new RegExp(regexPattern);

        // Check if URL matches the regex pattern
        if (regex.test(url)) {
            return endpoint;
        }
    }
    return null; // No match found
}

export {endpoints}