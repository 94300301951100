import styles from './GroupCardSingleItem.module.scss';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    editViewToggle,
    filterChannels,
    selectChannels,
    selectGroup,
    showAllChannels,
} from 'src/redux/actions/actions.channelGroupsWidget';

import classNames from 'classnames';
import { GroupCardProps } from './GroupCardSingleItem.types';
import { FC, SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

const GroupCardSingleItem : FC<GroupCardProps> = ({ 
    groupTitle, 
    id, 
    groups, 
    channels 
}: GroupCardProps) => {
    
    const dispatch = useAppDispatch();
    
    const { 
        selectedGroupId, 
        selectedChannels, 
        filteredChannels 
    } = useAppSelector(
        (state) => state.channelGroupsWidgetReducer
    );

    const editModeIsOpenHandler = (e: SyntheticEvent) => {
        e.stopPropagation();
        dispatch(selectGroup(id, groupTitle, groups, channels));
        dispatch(editViewToggle());
        dispatch(filterChannels(filteredChannels))
    };

    const selectGroupHandler = () => {
        selectedGroupId !== id && dispatch(selectGroup(id, groupTitle, groups, channels));
        selectedGroupId !== id && dispatch(selectChannels(selectedChannels))
        selectedGroupId !== id && dispatch(showAllChannels(false))
    };

    const truncatedText = groupTitle?.length > 20 ? groupTitle?.slice(0, 20) + '...' : groupTitle;

    return (
        <div
            onClick={selectGroupHandler}
            title={groupTitle}
            aria-label={`channelGroup-${id}`}
            data-testid={`channelGroup-${groupTitle}`}
            className={classNames(
                styles.groupCard,
                id === selectedGroupId && styles.selectedGroup
            )}
        >
            <div data-testid={`edit-icon-${groupTitle}`}>
            <FontAwesomeIcon
                onClick={editModeIsOpenHandler}
                className={styles.penIcon}
                icon={faPen}
            />
            </div>
            {truncatedText}
        </div>
    );
};

export default GroupCardSingleItem;
