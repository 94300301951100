import React, { Component } from 'react';
import Select from 'react-select';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './SelectAsync.scss';

// wrapper around react-select to allow showing a loading icon before data is available

class SelectAsync extends Component {
    constructor(props){
        super(props);

        this.state = {
            optionsCount: 0 // used to always auto-close menu on select if it was the last item
        };
    }

    componentDidMount(){
        if(this.props.options){
            this.setState({optionsCount: this.countOptions(this.props.options)});
        }
    }

    componentDidUpdate(prevProps){
        let updates = {};
        if(prevProps.options !== this.props.options){
            updates.optionsCount = this.countOptions(this.props.options);
            this.setState({...updates});
        }
        if(this.props.value && this.props.value.length === this.state.optionsCount && this.selectRef){
            this.selectRef.blur();  // lose focus if all dropdown options have already been selected (even if closeMenuOnSelect is set to false)
        }
    }

    // count number of selectable options
    countOptions(opts){
        return opts.reduce((sum, o) => ( o.options ? sum += o.options.length : sum += 1 )
        ,0);
    }

    render() {
        let props = _.cloneDeep(this.props);
        if (props.selectAll) {
            const hasOptions = this.props.options && this.props.options.length > 0;
            props.options = hasOptions && this.props.isMulti ? [{label: props.t('Select all'),value:'selectAll'}, ...this.props.options] : this.props.options;
            props.onChange = (selected) => {
                    selected && selected.find(o => o.value === 'selectAll') ?
                        this.props.options[0].options ? 
                            this.props.onChange(this.props.options.reduce((sum, val) => ( sum.concat(val.options) ),[]))
                        : this.props.onChange(this.props.options.filter(e=>!e.isDisabled))
                    : this.props.onChange(selected);
            }
        }
        delete props.loading;
        delete props.spinner;
        delete props.spinnerText;
        const loading = this.props.loading || false;
        return (
            <Select
                styles={{
                    menu: (base) => ({
                        ...base,
                        width: "max-content",
                        minWidth: "100%",
                        
                    }),
                    menuList: (base) => ({
                        ...base,
                        ...this.props.menuList,
                        maxHeight: "150px",
                        
                    }),
                    groupHeading: (base) => {
                        return {
                            ...base,
                            backgroundColor: this.props.theme.primary,
                            color: 'white',
                            padding: '0.6rem',
                            marginBlockEnd: 0,
                        }
                    },
                    group: (base) => {
                        return {...base, padding:0}
                    }
                }
                }
                    ref={(r)=>{ this.selectRef = r; }}
                    instanceId={this.props.instanceId}
                    classNamePrefix="react-select-async"
                    isDisabled={loading ? true : false}
                    {...props}
                    className={(props.selectAll ? 'select-all ' : '') + props.className}
                    // formatGroupLabel={(...opts) => { }}
                    // formatOptionLabel={(...opts) => { }}
                    placeholder={
                        loading ?(
                            <LoadingSpinner
                                prependText={this.props.spinnerText}
                                size='1rem'
                                fullSize={false}
                        /> 
                        ):(
                            props.placeholder
                        )
                    }
                />
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(SelectAsync));