import React from 'react'
import { appendQueryString, sanitizeUrlParamsObj } from '../common/helpers';
import config from 'src/config/config';

function useEPG() {

    const getBaseUrl = (channelMediaType: number) => {
        return [1, 2].includes(channelMediaType)
            ? `${config.urls.mediaEditor}`
            : '/cgi-bin/feed/stview';
    };

    const createShowMedialUrl = (channelMediaType:number, channelId: number, timestamp:number, timezone:string) => {
        const params = new URLSearchParams(sanitizeUrlParamsObj({
            story: `i:${channelId}:${timestamp}`,
            timezone: timezone,
        }));
        return appendQueryString(getBaseUrl(channelMediaType), params);
    }

    const createShowMedialUrlTZ = (channelMediaType:number, channelId: number, dateTimeString:string, timezone:string) => {
        const params = new URLSearchParams(sanitizeUrlParamsObj({
            storyTZ: `i:${channelId}:${dateTimeString}`,
            timezone: timezone,
        }));
        return appendQueryString(getBaseUrl(channelMediaType), params);
    }

    return {
        createShowMedialUrl,
        createShowMedialUrlTZ
    }
}

export default useEPG