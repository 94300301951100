import { RECEIVE_ITEMS, POST_ITEM, DELETE_ITEM } from '../types/actionTypes.epgHistory';
import APISettings from '../../API/APISettings';
import _ from 'lodash';
import { setRemoveOldEpgHistoryRunning } from './actions.epgHistoryState';

export const receivedItems = (data) => ({
    type: RECEIVE_ITEMS,
    data: data
});

export const postedItem = (data) => ({
    type: POST_ITEM,
    data: data
});

export const deletedItem = (id) => ({
    type: DELETE_ITEM,
    id: id
});


export const getEpgHistory = (options={shouldRemoveOldItems: false}) => {
    const store = window.rxStore.getState();
    const min = Math.max(
        store.me.archive.userArchiveStart,
        store.me.archive.dbArchiveStart
    );
    const max = new Date('2200-01-01');

    return (dispatch) => {
        return APISettings.getItems('epgHistory').then(async(res) => {
            let toDelete = [];
            let toKeep = [];
            if (res) {
                res?.data?.map(e => {
                    if (e?.timestamp * 1000 < min || e?.timestamp * 1000 > max) {
                        toDelete.push(e);
                    } else {
                        toKeep.push(e);
                    }
                });
            }

            // add valid history items to redux store
            dispatch(receivedItems(toKeep));

            if (options.shouldRemoveOldItems) {
                // delete all old history items
                // (where timestamp indicates that there is no video / audio for this date anymore)

                dispatch(setRemoveOldEpgHistoryRunning(true));
                for (const itemToDelete of toDelete) {
                    await dispatch(deleteEpgHistoryItem(itemToDelete.id, undefined, [930008]))
                };
                dispatch(setRemoveOldEpgHistoryRunning(false));
            }
            return res;
        })
    }
};

export const postEpgHistoryItem = (data) => {
    return async (dispatch, getState) => {
        let history = getState().epgHistory;
        // if (history.length === 0) {
            dispatch(getEpgHistory()).then((res) => {
                history = res.data;
                post();
            });
        // } else {    // NOTE: add this to reduce fetches (but risk duplicates when using multiple v3 instances simultaneously)
        //     return post();
        // }
        
        function post() {
            if (!historyExists(data, history)) {
                return APISettings.postItem('epgHistory', data, [503]).then((res) => {
                    if(res?.rc === 0){
                        data.id = res.data.id;
                        dispatch(postedItem(data));
                    }
                    return res;
                })
            }
        }
    }
};

export const deleteEpgHistoryItem = (id, httpErrorsNoUserInfo, hideRCs) => {
    return async (dispatch) => {
        const res = await APISettings.deleteItem('epgHistory', id, httpErrorsNoUserInfo, hideRCs)//.then((res) => {})
        if (res.rc === 0) {
            dispatch(deletedItem(id));
        }
        return res;
    }
};

const historyExists = (newItem, currentHistory) => {    // checks if item with same props already exists in the list that is stored inside the redux store
    currentHistory = _.cloneDeep(currentHistory);
    newItem = _.omitBy(newItem, _.isUndefined);
    delete newItem.id;
    return _.find(currentHistory, o => { delete o.id; return _.isEqual(_.omitBy(o, _.isUndefined), newItem)}) === undefined ? false : true;
}